import { toast, Slide } from 'react-toastify';

const TOAST_CONFIG = {
  autoClose: 4000,
  draggable: false,
  type: toast.TYPE.INFO,
  position: toast.POSITION.BOTTOM_CENTER,
  transition: Slide,
  hideProgressBar: true,
  className: 'react-toast-container',
  toastClassName: 'react-toast',
  bodyClassName: 'react-toast-body',
  progressClassName: 'react-toast-progress',
};

export default TOAST_CONFIG;
