import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { SignIn, SignUp, ForgotPassword, LocationInfo, CounterInfo, Options, Counters, Dashboard, HistoryDashboard } from 'app/views';
import { PrivateRoute } from 'common/navigation';
import * as NAVIGATION from 'config/navigation';


const Routes = () => (
  <Switch>
    <Route
      path={NAVIGATION.SIGNIN}
      component={SignIn}
    />
    <Route
      path={NAVIGATION.SIGNUP}
      component={SignUp}
    />
    <Route
      path={NAVIGATION.FORGOT_PASSWORD}
      component={ForgotPassword}
    />
    <PrivateRoute
      path={NAVIGATION.LOCATION_INFO}
      component={LocationInfo}
    />
    <PrivateRoute
      path={NAVIGATION.COUNTERS}
      component={Counters}
    />
    <PrivateRoute
      path={`${NAVIGATION.COUNTER_INFO}/:counterId`}
      component={CounterInfo}
    />
    <PrivateRoute
      path={NAVIGATION.COUNTER_INFO}
      component={CounterInfo}
    />
    <PrivateRoute
      path={NAVIGATION.OPTIONS}
      component={Options}
    />
    <PrivateRoute
      path={NAVIGATION.MAIN}
      component={Dashboard}
      exact
    />
    <PrivateRoute
      path={NAVIGATION.HISTORY_DASHBOARD}
      component={HistoryDashboard}
    />
  </Switch>
);

export default Routes;
