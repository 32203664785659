import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import MuiTextField from '@material-ui/core/TextField';


const RootField = (props) => {
  const { InputProps, className, ...rest } = props;
  const { classes: InputProps_classes = {}, inputProps: InputProps_inputProps = {}, ...InputProps_rest } = InputProps;

  return (
    <MuiTextField
      className={classnames('base-input-field', { [className]: className })}
      InputLabelProps={{
        classes: {
          root: "base-input-field-label",
          error: "base-input-field-error-text",
        },
      }}
      InputProps={{
        classes: {
          root: "base-input-field-input-wrapper",
          ...InputProps_classes,
        },
        inputProps: {
          className: "base-input-field-input",
          ...InputProps_inputProps,
        },
        ...InputProps_rest,
      }}
      FormHelperTextProps={{
        classes: {
          error: "base-input-field-error-text",
        },
      }}
      {...rest}
    />
  );
};

RootField.propTypes = {
  className: PropTypes.string,
  InputProps: PropTypes.object,
  margin: PropTypes.string,
  variant: PropTypes.string,
};

RootField.defaultProps = {
  InputProps: {},
  margin: 'normal',
  variant: 'filled',
};


export default RootField;
