import { makeActionCreator } from 'service/utility';

export const SET_ACCOUNT = 'SET_ACCOUNT';
export const setAccount = makeActionCreator(SET_ACCOUNT, 'account');

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const setLocations = makeActionCreator(SET_LOCATIONS, 'locations');

export const SW_INIT = 'SW_INIT';
export const swInit = makeActionCreator(SW_INIT);

export const SW_UPDATE = 'SW_UPDATE';
export const swUpdate = makeActionCreator(SW_UPDATE, 'payload');
