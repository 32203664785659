import axios from 'axios';
import AppConfig from 'config/app';
import { getIdToken } from 'service/firebase';

export const getNamespace = () => {
  return 'peoplecount';
};


export const initAxios = async () => {
  const token = await getIdToken();
  const bearer = getNamespace();

  const instance = axios.create({
    baseURL: `${AppConfig.apiRoot}`,
    headers: { Authorization: `${bearer} ${token}` },
  });

  instance.interceptors.request.use((config) => {
    console.log(`Making axios API call: ${config.method.toUpperCase()} ${config.url}`);
    return config;
  });

  instance.interceptors.response.use((response) => {
    console.log('Response of axios API call: ', response.data);
    return response;
  });

  return instance;
};
