import { useEffect } from 'react';


export const makeActionCreator = (type, ...argNames) => (...args) => {
  const action = { type };

  argNames.forEach((arg, index) => action[argNames[index]] = args[index]);

  return action;
};

export const createReducer = (initialState, handlers) => (state = initialState, action) => {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  }

  return state;
};

export const useEffectAsync = (effect, inputs, cleanup) => {
// eslint-disable-next-line
  useEffect(() => {
  // eslint-disable-next-line
    effect();
    // eslint-disable-next-line
    if (cleanup) {
    // eslint-disable-next-line
      return cleanup;
    // eslint-disable-next-line
    }
  // eslint-disable-next-line
  }, inputs);
};

export const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

export const now = () => new Date().toISOString();

export const getErrorMessage = (error) => {
  if (typeof error === 'string') return error;
  if (typeof error === 'undefined') return 'Unknown error';
  if (typeof error !== 'object') return JSON.stringify(error);

  const { response } = error;

  if (typeof response === 'string') return response;
  if (typeof response === 'undefined') return 'Unknown error';
  if (typeof response !== 'object') return JSON.stringify(response);

  const { data } = response;

  if (typeof data === 'string') return data;
  if (typeof data === 'undefined') return 'Unknown error';
  if (typeof data !== 'object') return JSON.stringify(data);

  const { message } = data;

  if (typeof message === 'string') return message;
  if (typeof message === 'undefined') return 'Unknown error';

  return JSON.stringify(message);
};

export const commaJoin = (arr) => arr.filter((e) => e).join(',');
