import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Button, ListItemText } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';

import { appSelectors } from 'app/redux';


const LocationSelector = (props) => {
  const currentLocation = props.currentLocations && props.currentLocations[0];

  return (
    <Button
      onClick={() => {}}
      color="inherit"
      className="header-button location-selector"
    >
      <BusinessIcon className="business-icon" />
      <ListItemText
        primary={currentLocation ? currentLocation.name : 'Set Location'}
        secondary="No Stripe Account"
        classes={{
          root: 'text-container',
          primary: 'location-name',
          secondary: 'location-secondary',
        }}
      />
    </Button>
  );
};

LocationSelector.propTypes = {
  currentLocations: PropTypes.array,
};


const mapStateToProps = (state) => ({
  currentLocations: appSelectors.currentLocations(state),
});

export default connect(mapStateToProps)(LocationSelector);
