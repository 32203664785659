import { select } from 'redux-saga/effects';

import { currentUser } from '../selectors/authSelectors';


export function *setGoogleAnalyticsUser() {
  if (!window.gtag) return;

  const user = yield select(currentUser);

  if (user.isLogged) {
    window.gtag('config', window._GA_ID, { 'user_id': user.currentUserUID });
    window.gtag('set', 'user_properties', { 'crm_id' : user.currentUserUID });
  } else {
    window.gtag('config', window._GA_ID);
    window.gtag('set', 'user_properties', { 'crm_id' : null });
  }
}