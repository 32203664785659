import * as api from 'service/api';
import { now, getErrorMessage } from 'service/utility';

export const createAccount = async ({ newAccount, onSuccess, onError }) => {
  console.log(`${now()} - attempting to create a new account with the following payload:`);
  console.log(newAccount);

  try {
    const { data: account } = await api.createAccount(newAccount);

    console.log(`${now()} - response of api.createAccount:`);
    console.log(account);

    await onSuccess(account);
  } catch (error) {
    console.log('api.createAccount error');
    console.log(error);

    onError(getErrorMessage(error));
  }
};

export const getAccount = async ({ onSuccess, onError }) => {
  try {
    const { data: account } = await api.getAccount();

    console.log(`${now()} - response of api.getAccount:`);
    console.log(account);

    await onSuccess(account);
  } catch (error) {
    console.log('api.getAccount error');
    console.log(error);

    onError(getErrorMessage(error));
  }
};

export const upsertLocation = async ({ newLocation, onSuccess, onError }) => {
  console.log(`${now()} - attempting to ${newLocation.id ? 'update' : 'create'} a location with the following payload:`);
  console.log(newLocation);

  try {
    const { data: location } = await api.upsertLocation(newLocation);

    console.log(`${now()} - response of api.upsertLocation:`);
    console.log(location);

    await onSuccess(location);
  } catch (error) {
    console.log('api.upsertLocation error');
    console.log(error);

    onError(getErrorMessage(error));
  }
};

export const getLocation = async ({ locationId, onSuccess, onError }) => {
  try {
    const { data: location } = await api.getLocation(locationId);

    console.log(`${now()} - response of api.getLocation:`);
    console.log(location);
  
    await onSuccess(location);
  } catch (error) {
    console.log('api.getLocation error');
    console.log(error);

    onError(getErrorMessage(error));
  }
};

export const getLocations = async ({ onSuccess, onError }) => {
  try {
    const { data: locations } = await api.getLocations();

    console.log(`${now()} - response of api.getLocations:`);
    console.log(locations);
  
    await onSuccess(locations);
  } catch (error) {
    console.log('api.getLocations error');
    console.log(error);

    onError(getErrorMessage(error));
  }
};

export const upsertCounter = async ({ locationId, counter, onSuccess, onError }) => {
  console.log(`${now()} - attempting to upsert a counter for location with id = ${locationId} with the following payload:`);
  console.log(counter);

  try {
    const { data: updatedCounter } = await api.upsertCounter(locationId, counter);

    console.log(`${now()} - response of api.upsertCounter:`);
    console.log(updatedCounter);

    await onSuccess(updatedCounter);
  } catch (error) {
    console.log('api.upsertCounter error');
    console.log(error);

    onError(getErrorMessage(error));
  }
};

export const getCounter = async ({ locationId, counterId, onSuccess, onError }) => {
  try {
    const { data: counter } = await api.getCounter(locationId, counterId);

    console.log(`${now()} - response of api.getCounter:`);
    console.log(counter);
  
    await onSuccess(counter);
  } catch (error) {
    console.log('api.getCounter error');
    console.log(error);

    onError(getErrorMessage(error));
  }
};
