import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';

import LOGO from 'assets/pc-squircle-1024-transparent.png';
import { EmailField } from 'common/formFields';
import { ButtonSpinner } from 'common/statusIndicators';
import * as NAVIGATION from 'config/navigation';
import * as authService from 'service/firebase';


const ForgotPassword = (props) => {
  const [email, setEmail] = React.useState('');
  const [validationErrors, setValidationErrors] = React.useState({});
  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [passwordWasReset, setPasswordWasReset] = React.useState(false);


  const handleError = (error) => {
    console.log('ForgotPassword error: ');
    console.log(error);

    toast.error(error);
  };

  const sendEmail = async () => {
    setSendingEmail(true);
    const response = await authService.resetPassword(email);

    if (response.success) {
      setSendingEmail(false);
      setPasswordWasReset(true);
    } else {
      setSendingEmail(false);
      handleError(response.error.message);
    }
  };

  const handleChange = (varName, setStateFunc) => (update) => {
    const newValue = update.hasOwnProperty('value');
    const newError = update.hasOwnProperty('error');

    if (newValue) {
      setStateFunc(update.value);
    }

    if (newError) {
      setValidationErrors((prevValidationErrors) => ({
        ...prevValidationErrors,
        [varName]: update.error,
      }));
    }
  };


  return (
    <div className="onboarding-page light-gradient">
      <div className="logo-title-row">
        <Link
          to={NAVIGATION.MAIN}
          className="text-decoration-none"
        >
          <img
            src={LOGO}
            className="logo"
            alt="logo"
          />
          <span className="title">
            {'People Count'}
          </span>
        </Link>
      </div>
      <div className="inner-container dark-gradient">
        <div className="form">
          <span className="inner-title">
            {'Forgot your password?'}
          </span>
          <span className="inner-secondary-title">
            {!passwordWasReset ? (
              'Enter the email you signed up with and click the button below.'
            ) : (
              'A password reset email was sent. Follow the instructions in the email to finish resetting your password, then go the Sign In screen.'
            )}
          </span>
          {!passwordWasReset && (
            <EmailField
              label="Email"
              value={email}
              error={validationErrors.email}
              onChange={handleChange('email', setEmail)}
              placeholder="johndoe@mail.com"
              required
              fullWidth
              autoFocus
            />
          )}
        </div>
        <div className="button-wrapper">
          {!passwordWasReset ? (
            <Button
              variant="contained"
              className="main-button"
              disabled={sendingEmail}
              onClick={sendEmail}
            >
              {sendingEmail && <ButtonSpinner />}
              {'RESET PASSWORD'}
            </Button>
          ) : (
            <Button
              variant="contained"
              className="main-button"
              component={Link}
              to={NAVIGATION.SIGNIN}
            >
              {'GO TO SIGN IN'}
            </Button>
          )}
          <Button
            className="secondary-button"
            component={Link}
            to={NAVIGATION.SIGNIN}
          >
            {'Already have an account?'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
