import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Avatar, List, ListItem, ListItemAvatar, ListItemText, Button } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import ReorderIcon from '@material-ui/icons/Reorder';
import TodayIcon from '@material-ui/icons/Today';
import { appSelectors } from 'app/redux';
import { Spinner } from 'common/statusIndicators';
import { CHART_TYPES_STRING } from 'config/charts';
import * as API from 'service/api';
import { getLocationTimezone } from 'service/i18n';
import { useEffectAsync } from 'service/utility';
import * as timeUtils from 'service/utility/dateTime';

// import { DateField } from 'common/formFields';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { HistoryChart, SimpleChartTooltip, CompositeChartTooltip } from './components';

const Dashboard = (props) => {
  const currentLocation = props.currentLocations && props.currentLocations[0];
  const timeZone = getLocationTimezone(currentLocation);
  const locationId = currentLocation ? currentLocation.id : null;

  const timeSelectors = ['Days', 'Weeks', 'Months'];
  const rangeDateChartTypes = {'Days': 'rangeByDay', 'Weeks': 'rangeByWeek', 'Months': 'rangeByMonth'};
  const [viewTimeSelected, setViewTimeSelected] = React.useState(timeSelectors[0]);

  const handleViewTime = (event, newViewTimeSelector) => {
    setViewTimeSelected(newViewTimeSelector);
  };
  const [validationErrors, setValidationErrors] = React.useState({});
  const [chartTypes, setChartTypes] = React.useState({});

  const [dateRange, setDateRange] = React.useState(
    [timeUtils.iso8601MidnightLessDaysInUTC(timeZone, 30), 
      timeUtils.iso8601MidnightLessDaysInUTC(timeZone, 0)]
    );
  const [startDate, endDate] = dateRange;
  const [isSingleDate, setIsSingleDate] = React.useState(false);
  // const handleChange = (varName, setStateFunc) => (update) => {
  //   const newValue = update.hasOwnProperty('value');
  //   const newError = update.hasOwnProperty('error');

  //   if (newValue) {
  //     setStateFunc(update.value);
  //   }

  //   if (newError) {
  //     setValidationErrors((prevValidationErrors) => ({
  //       ...prevValidationErrors,
  //       [varName]: update.error,
  //     }));
  //   }
  // };

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  const handleDateChange = (update) => {
    const [startd, endd] = update;
    setDateRange(update);

    if (timeUtils.isSameDate(timeUtils.iso8601FromDateJS(startd), timeUtils.iso8601FromDateJS(endd)) || endd === null ) {
      setIsSingleDate(true);
    } else {
      setIsSingleDate(false);
    }
  }

  const loadCharts = async () => {
    setLoading(true)
    if (locationId) {
      let startDT = startDate ? timeUtils.fromDateJStoUTCMidnight(startDate) : timeUtils.iso8601MidnightTodayInUTC(timeZone);
      let endDT = endDate ? timeUtils.fromDateJStoUTCEod(endDate) : timeUtils.fromDateJStoUTCEod(startDate);
      const chartsTypeString = isSingleDate ? CHART_TYPES_STRING : rangeDateChartTypes[viewTimeSelected];

      const { data: data2 } = await API.getCharts(
        `startDT=${startDT}&endDT=${endDT}&locationId=${locationId}&types=${chartsTypeString}`
      );
      let data3;
      if(isSingleDate) {
        data3 = {
          dailyAvg: Math.round(data2.dailyAvg.count),
          dailyHigh: data2.dailyHigh.count,
          dailyTraffic: data2.dailyTraffic.count,
          hourly: Array.from(new Array(data2.hourlyCount.length)).map((_item, idx) => ({
            hour: data2.hourlyCount[idx].hour,
            incomingCount: data2.hourlyIncoming[idx].count,
            outgoingCount: data2.hourlyOutgoing[idx].count,
            netCount: data2.hourlyCount[idx].count + (data2.hourlyIncoming[idx].count - data2.hourlyOutgoing[idx].count),
            hourlyCount: data2.hourlyCount[idx].count,
          })),
        };
      } else {
        const range = data2['rangeByDay'] || data2['rangeByWeek'] || data2['rangeByMonth'] || [];
        data3 = {
          dataByRange: range
        }
      }

      setData(data3);
    }

    setLoading(false);
  };

  useEffectAsync(loadCharts, dateRange);
  useEffectAsync(loadCharts, [viewTimeSelected]);
  if (!locationId) return null;

  return (
    <div className="dashboard-page light-gradient">

          <div className="row g-0">
            <div className="col-xs-12 col-lg-6 col-xl-3" style={{paddingLeft:'1rem'}}>
              <label className="p-1">Pick a Date Range</label>
              <br />
              <DatePicker
                todayButton="Today"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                isClearable={true}
                className="datepicker"
              />
            </div>
            { !isSingleDate && (
              <div className="col-xs-12 col-lg-4" style={{paddingLeft:'1rem'}}>
                <label className="p-1">Group by</label>
                <div className="d-flex align-items-center p-3">
                  <ToggleButtonGroup
                    value={viewTimeSelected}
                    exclusive
                    onChange={handleViewTime}
                  >
                    {timeSelectors.map((selector) => (
                      <ToggleButton key={selector} value={selector}>
                      {selector}
                    </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </div>
              </div>
            )}
          </div>
          {loading ? (
            <Spinner />
          ) : !data ? (
            null
          ) : (
            <React.Fragment>
          <div className="row g-0">
            <div className="col-xs-12 p-4">
              <h4>Showing Results for: {!isSingleDate ? `${timeUtils.formatShortDate(timeUtils.iso8601FromDateJS(startDate))} - ${timeUtils.formatShortDate(timeUtils.iso8601FromDateJS(endDate))}` : timeUtils.formatShortDate(timeUtils.iso8601FromDateJS(startDate))}</h4>
            </div>
          </div>
          {isSingleDate ? (
            <React.Fragment>
              <div className="row g-0 align-items-center">
                <div className="col-12 d-lg-flex align-items-center">
                  <h1 className="p-4">Today's Traffic</h1>
                  <h5 className="p-4"><TodayIcon fontSize="inherit" /> {timeUtils.todayDatetime(timeZone)}</h5>
                </div>
                {/* <div className="col-lg-6 text-right">
                  Current people count:
                </div> */}
              </div>
              {/* {data.hourly.length > 0 ? ( */}
                <div className="row g-0">
                <div className="col-xs-12 col-xl-6">
                  <HistoryChart
                    title="Incoming by Hour"
                    data={data.hourly}
                    xAxisDataKey="hour"
                    yAxis={[
                      {
                        dataKey: 'incomingCount',
                        name: 'Incoming Count',
                        color: '#4c40b8',
                      },
                    ]}
                    tooltipComponent={<SimpleChartTooltip />}
                  />
                </div>
                <div className="col-xs-12 col-xl-6">
                  <HistoryChart
                    title="Outgoing by Hour"
                    data={data.hourly}
                    xAxisDataKey="hour"
                    yAxis={[
                      {
                        dataKey: 'outgoingCount',
                        name: 'Outgoing Count',
                        color: '#f92b75',
                      },
                    ]}
                    tooltipComponent={<SimpleChartTooltip />}
                  />
                </div>
              {/* </div>
              <div className="row g-0"> */}
                <div className="col-xs-12 col-xl-6">
                  <HistoryChart
                    title="Number of people in the building by Hour"
                    data={data.hourly}
                    xAxisDataKey="hour"
                    yAxis={[
                      {
                        dataKey: 'netCount',
                        name: 'Net Count',
                        color: '#ffa600',
                      },
                    ]}
                    tooltipComponent={<CompositeChartTooltip />}
                  />
                </div>
                <div className="col-xs-12 col-xl-6">
                  <HistoryChart
                    title="Composite Hourly Traffic"
                    data={data.hourly}
                    xAxisDataKey="hour"
                    yAxis={[
                      {
                        dataKey: 'incomingCount',
                        name: 'Incoming Count',
                        color: '#4c40b8',
                      },
                      {
                        dataKey: 'outgoingCount',
                        name: 'Outgoing Count',
                        color: '#f92b75',
                      },
                      {
                        dataKey: 'netCount',
                        name: 'Net Count',
                        color: '#ffa600',
                      },
                    ]}
                    tooltipComponent={<CompositeChartTooltip />}
                  />
                </div>
              </div>
              <Paper
                elevation={0}
                className="paper-container"
              >
                <List className="stats-list">
                  <ListItem className="stat-list-item">
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        className="stat-icon"
                      >
                        <ArrowUpwardIcon fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Highest # of people in building today"
                      secondary={data.dailyHigh}
                      classes={{
                        primary: 'stat-primary',
                        secondary: 'stat-secondary',
                      }}
                    />
                  </ListItem>
                  <ListItem className="stat-list-item">
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        className="stat-icon"
                      >
                        <ReorderIcon fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Avg. # of people in building per hour"
                      secondary={data.dailyAvg}
                      classes={{
                        primary: 'stat-primary',
                        secondary: 'stat-secondary',
                      }}
                    />
                  </ListItem>
                  <ListItem className="stat-list-item">
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        className="stat-icon"
                      >
                        <PeopleAltOutlinedIcon fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Total # of visitors today"
                      secondary={data.dailyTraffic}
                      classes={{
                        primary: 'stat-primary',
                        secondary: 'stat-secondary',
                      }}
                    />
                  </ListItem>
                </List>
              </Paper>
            </React.Fragment>
          ) : (
            <div className="row g-0">
              <div className="col-xs-12 col-xl-12">
                <HistoryChart
                  title="Incoming Traffic"
                  data={data.dataByRange}
                  xAxisDataKey="date"
                  yAxis={[
                    {
                      dataKey: 'count',
                      name: 'Count',
                      color: '#4c40b8',
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

Dashboard.propTypes = {
  currentLocations: PropTypes.array,
};


const mapStateToProps = (state) => ({
  currentLocations: appSelectors.currentLocations(state),
});

export default connect(mapStateToProps)(Dashboard);
