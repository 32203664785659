import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.scss';
import './styles/loader.scss';
import './styles/bootstrap.scss';
import 'react-toastify/dist/ReactToastify.css';

import { appActions, getStore } from './app/redux';
import { saveState } from './service/storage';

import App from './app';
import * as serviceWorker from './serviceWorkerRegistration';


const store = getStore();

store.subscribe(() => {
  saveState(store.getState());
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register({
  onSuccess: () => store.dispatch({ type: appActions.SW_INIT }),
  onUpdate: (registration) => store.dispatch({ type: appActions.SW_UPDATE, payload: registration }),
});
