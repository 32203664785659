import { createReducer } from 'service/utility';

import { appActions } from '../actions';


const initialState = null;

export default createReducer(initialState, {
  [appActions.SET_ACCOUNT]: (state, { account }) => account,
});
