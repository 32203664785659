import React from 'react';
import { Paper } from '@material-ui/core';


const SimpleChartTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    const { value } = payload[0];

    return (
      <Paper className="chart-tooltip">
        <p>
          <span className="fw-bold">
            {value}
          </span>
          <span className="tooltip-text">
            {' visitors'}
          </span>
        </p>
      </Paper>
    );
  }

  return null;
};


export default SimpleChartTooltip;
