import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';

import { appActions, authActions, authSelectors } from 'app/redux';
import LOGO from 'assets/pc-squircle-1024-transparent.png';
import { PasswordField, EmailField } from 'common/formFields';
import { ButtonSpinner } from 'common/statusIndicators';
import * as NAVIGATION from 'config/navigation';
import * as authService from 'service/firebase';
import { getErrorMessage } from 'service/utility';


const SignIn = (props) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [validationErrors, setValidationErrors] = React.useState({});
  const [signingIn, setSigningIn] = React.useState(false);
  const [startingApp, setStartingApp] = React.useState(false);


  const handleError = (error) => {
    setSigningIn(false);

    console.log('SignIn error: ');
    console.log(error);

    toast.error(error);
  };

  const handleChange = (varName, setStateFunc) => (update) => {
    const newValue = update.hasOwnProperty('value');
    const newError = update.hasOwnProperty('error');

    if (newValue) {
      setStateFunc(update.value);
    }

    if (newError) {
      setValidationErrors((prevValidationErrors) => ({
        ...prevValidationErrors,
        [varName]: update.error,
      }));
    }
  };

  const handleSignIn = async () => {
    setSigningIn(true);

    try {
      const { currentUser, error } = await authService.signIn(email, password);

      setSigningIn(false);

      if (currentUser) {
        setStartingApp(true);
        props.setCurrentUser(currentUser);
      } else {
        handleError(error.message);
      }
    } catch (error) {
      handleError(getErrorMessage(error));
    }
  };


  const { currentUser: { isLogged } } = props;

  return !isLogged ? (
    <div className="onboarding-page light-gradient">
      <div className="logo-title-row">
        <Link
          to={NAVIGATION.MAIN}
          className="text-decoration-none"
        >
          <img
            src={LOGO}
            className="logo"
            alt="logo"
          />
          <span className="title">
            {'People Count'}
          </span>
        </Link>
      </div>
      <div className="inner-container dark-gradient">
        <div className="form">
          <span className="inner-title">
            {'Welcome!'}
          </span>
          <span className="inner-secondary-title">
            {'Sign in to your account.'}
          </span>
          <EmailField
            label="Email"
            value={email}
            error={validationErrors.email}
            onChange={handleChange('email', setEmail)}
            placeholder="johndoe@mail.com"
            required
            fullWidth
            autoFocus
          />
          <PasswordField
            label="Password"
            value={password}
            error={validationErrors.password}
            onChange={handleChange('password', setPassword)}
            placeholder="········"
            required
            fullWidth
          />
        </div>
        <div className="button-wrapper">
          <Button
            variant="contained"
            className="main-button"
            disabled={signingIn || startingApp}
            onClick={handleSignIn}
          >
            {signingIn ? 'Signing In...' : startingApp ? 'Starting App...' : 'SIGN IN'}
            {(signingIn || startingApp) && <ButtonSpinner />}
          </Button>
          <Button
            className="secondary-button"
            component={Link}
            to={NAVIGATION.SIGNUP}
            disabled={signingIn || startingApp}
          >
            {'Don\'t have an account?'}
          </Button>
          <Button
            className="secondary-button"
            component={Link}
            to={NAVIGATION.FORGOT_PASSWORD}
            disabled={signingIn || startingApp}
          >
            {'Forgot your password?'}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <Redirect
      to={{
        pathname: NAVIGATION.MAIN,
        state: { from: NAVIGATION.SIGNIN },
      }}
    />
  );
};

SignIn.propTypes = {
  currentUser: PropTypes.object.isRequired,
  setAccount: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  setLocations: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentUser: authSelectors.currentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  setAccount: (account) => dispatch(appActions.setAccount(account)),
  setCurrentUser: (user) => dispatch(authActions.setCurrentUser(user)),
  setLocations: (locations) => dispatch(appActions.setLocations(locations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
