import { makeActionCreator } from 'service/utility';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const setCurrentUser = makeActionCreator(SET_CURRENT_USER, 'user');

export const AUTH_LOGGED_IN_SUCCESS = 'AUTH_LOGGED_IN_SUCCESS';
export const authLoggedInSuccess = makeActionCreator(AUTH_LOGGED_IN_SUCCESS, 'user');

export const LOG_OUT_USER = 'LOG_OUT_USER';
export const logOutUser = makeActionCreator(LOG_OUT_USER);

export const AUTH_LOGGED_OUT_SUCCESS = 'AUTH_LOGGED_OUT_SUCCESS';
export const authLoggedOutSuccess = makeActionCreator(AUTH_LOGGED_OUT_SUCCESS);
