import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Button } from '@material-ui/core';

import { appActions, appSelectors } from 'app/redux';
import { TextField, NumberField, TimezoneField, ViewField, TimeField } from 'common/formFields';
import { Spinner, ButtonSpinner } from 'common/statusIndicators';
import * as NAVIGATION from 'config/navigation';
import { TIMEZONE_MAP } from 'config/timezones';
import * as crud from 'service/crud';
import { useEffectAsync } from 'service/utility';
import * as timeUtils from 'service/utility/dateTime';


const LocationInfo = (props) => {
  const initialState = {
    name: null,
    address: null,
    phone: null,
    notes: null,
    maxCount: null,
    timeZone: null,
    isOpenAllDay: null,
    openTime: null,
    closeTime: null
  };

  const currentLocation = props.currentLocations && props.currentLocations[0];
  const locationId = currentLocation ? currentLocation.id : null;


  const history = useHistory();

  const [loading, setLoading] = React.useState(true);
  const [editing, setEditing] = React.useState(!locationId);
  const [name, setName] = React.useState(initialState.name);
  const [address, setAddress] = React.useState(initialState.address);
  const [phone, setPhone] = React.useState(initialState.phone);
  const [notes, setNotes] = React.useState(initialState.notes);
  const [maxCount, setMaxCount] = React.useState(initialState.maxCount);
  const [timeZone, setTimeZone] = React.useState(initialState.timeZone);
  const [isOpenAllDay, setIsOpenAllDay] = React.useState(initialState.isOpenAllDay);
  const [openTime, setOpenTime] = React.useState(timeUtils.iso8601MidnightToday(timeZone));
  const [closeTime, setCloseTime] = React.useState(timeUtils.iso8601TimeAddHoursAndMinutes(timeUtils.iso8601NowTime(timeZone), 0, 30));
  const [validationErrors, setValidationErrors] = React.useState({});
  const [saving, setSaving] = React.useState(false);

  const handleDisplayTypeChange = (e) => {
    setIsOpenAllDay(e.target.value);
    console.log(isOpenAllDay);
  };

  const minOpenTime = timeUtils.dateMidnightToday();
  const [minCloseTime, setMinCloseTime] = React.useState(openTime);
  const maxTime = new Date().setHours(23, 59, 0, 0);

  const afterLocationIsRetrieved = async (location) => {
    setName(location.name || initialState.name);
    setAddress(location.address || initialState.address);
    setPhone(location.phone || initialState.phone);
    setNotes(location.notes || initialState.notes);
    setMaxCount(location.maxCount || initialState.maxCount);
    setTimeZone(location.timeZone || initialState.timeZone);
    setIsOpenAllDay(
      location.isOpenAllDay === null
        ? initialState.isOpenAllDay
        : location.isOpenAllDay
          ? 'true'
          : 'false'
    );
    const openTime_ = location.openTime || initialState.openTime;
    setOpenTime(openTime_);
    setCloseTime(location.closeTime || initialState.closeTime);
    if (openTime_) {
      setMinCloseTime(timeUtils.localDateFromIso8601Time(openTime_));
    }

    setLoading(false);
  };

  const loadLocation = async () => {
    if (!locationId) {
      console.log('initialState');
      setName(initialState.name);
      setAddress(initialState.address);
      setPhone(initialState.phone);
      setNotes(initialState.notes);
      setMaxCount(initialState.maxCount);
      setTimeZone(initialState.timeZone);
      setIsOpenAllDay(initialState.isOpenAllDay);
      setOpenTime(initialState.openTime);
      setCloseTime(initialState.closeTime);

      setLoading(false);
    } else {
      setLoading(true);

      await crud.getLocation({
        locationId,
        onSuccess: afterLocationIsRetrieved,
        onError: handleError,
      });
    }
  };

  useEffectAsync(loadLocation, [locationId]);


  const handleOpenTimeChange = (update) => {
    handleChange('openTime', setOpenTime)(update);
    handleChange('closeTime', setCloseTime)(update);

    if (update.hasOwnProperty('value')) {
      setMinCloseTime(timeUtils.localDateFromIso8601Time(update.value));
    }
  };

  const handleError = (error) => {
    setLoading(false);
    setSaving(false);

    console.log('LocationInfo error: ');
    console.log(error);

    toast.error(error);
  };

  const handleEditClick = () => {
    setEditing(true);
  };


  const finish = async () => {
    setSaving(false);
    history.push(NAVIGATION.MAIN);
  };

  const afterLocationsAreRetrieved = async (locations) => {
    props.setLocations(locations);

    await finish();
  };

  const loadLocations = async () => {
    await crud.getLocations({
      onSuccess: afterLocationsAreRetrieved,
      onError: handleError,
    });
  };

  const saveLocation = async () => {
    setSaving(true);

    const location = {
      name,
      address,
      phone,
      notes,
      maxCount,
      timeZone,
      isOpenAllDay: (isOpenAllDay === 'true' ? 1 : 0),
      openTime,
      closeTime
    };

    if (locationId) {
      location.id = locationId;
    }

    await crud.upsertLocation({
      newLocation: location,
      onSuccess: loadLocations,
      onError: handleError,
    });
  };

  const handleChange = (varName, setStateFunc) => (update) => {
    const newValue = update.hasOwnProperty('value');
    const newError = update.hasOwnProperty('error');

    if (newValue) {
      setStateFunc(update.value);
    }

    if (newError) {
      setValidationErrors((prevValidationErrors) => ({
        ...prevValidationErrors,
        [varName]: update.error,
      }));
    }
  };


  return (
    <div className="onboarding-page light-gradient">
      <div className="inner-container">
        <div className="form">
          <span className="inner-title">
            {'Location Information'}
          </span>
          {loading ? (
            <Spinner />
          ) : editing ? (
            <React.Fragment>
              {!locationId && (
                <span className="inner-secondary-title">
                  {'Let\'s collect some information about this location.'}
                </span>
              )}
              <TextField
                label="Location Name"
                value={name}
                error={validationErrors.name}
                onChange={handleChange('name', setName)}
                placeholder="Business Name"
                required
                fullWidth
                autoFocus
              />
              <TextField
                label="Address"
                value={address}
                error={validationErrors.address}
                onChange={handleChange('address', setAddress)}
                placeholder="383 Oak Lane, Kansas City, MO 64106"
                fullWidth
              />
              <TextField
                label="Phone"
                value={phone}
                error={validationErrors.phone}
                onChange={handleChange('phone', setPhone)}
                placeholder="816-807-2606"
                fullWidth
              />
              <TextField
                label="Notes"
                value={notes}
                error={validationErrors.notes}
                onChange={handleChange('notes', setNotes)}
                placeholder="Hours of Operation: 9am-5pm"
                fullWidth
                multiline
                rows={4}
              />
              <NumberField
                label="Max Capacity"
                value={maxCount}
                error={validationErrors.maxCount}
                onChange={handleChange('maxCount', setMaxCount)}
                placeholder="999"
                fullWidth
              />
              <TimezoneField
                label="Time Zone"
                value={timeZone}
                error={validationErrors.timeZone}
                onChange={handleChange('timeZone', setTimeZone)}
                placeholder="America/Chicago"
                fullWidth
              />

              <FormControl margin="normal" component="fieldset">
                <FormLabel>
                  Business hours
                </FormLabel>
                <RadioGroup
                  value={isOpenAllDay}
                  onChange={handleDisplayTypeChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Open 24 hours"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Pick a time range"
                  />
                </RadioGroup>
              </FormControl>
              {isOpenAllDay === 'false' && (
                <div className="d-flex align-items-center w-100">
                  <TimeField
                    className="mr-3"
                    value={openTime}
                    error={validationErrors.openTime}
                    label="Start time"
                    onChange={handleOpenTimeChange}
                    narrow
                    required
                    DatePickerProps={{
                      minTime: minOpenTime,
                      maxTime,
                    }}
                  />
                  <span>
                    {'to'}
                  </span>
                  <TimeField
                    value={closeTime}
                    error={validationErrors.closeTime}
                    label="End time"
                    onChange={handleChange('closeTime', setCloseTime)}
                    narrow
                    required
                    DatePickerProps={{
                      minTime: minCloseTime,
                      maxTime,
                    }}
                  />
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className="view-only-container">
              <ViewField
                label="Location Name"
                value={name}
              />
              <ViewField
                label="Address"
                value={address}
              />
              <ViewField
                label="Phone"
                value={phone}
              />
              <ViewField
                label="Notes"
                value={notes}
              />
              <ViewField
                label="Max Capacity"
                value={maxCount}
              />
              <ViewField
                label="Time Zone"
                value={TIMEZONE_MAP[timeZone]}
              />
              <ViewField
                label="Business Hours"
                value={
                  isOpenAllDay === null
                    ? 'Not set yet'
                    : isOpenAllDay === 'true'
                      ? 'Open 24 hours'
                      : `Open from ${timeUtils.isoTimeToLocaleString(openTime)} to ${timeUtils.isoTimeToLocaleString(closeTime)}`
                }
              />
            </div>
          )}
        </div>
        <div className="button-wrapper">
          {!loading && Boolean(locationId) && !editing && (
            <Button
              variant="contained"
              className="main-button"
              onClick={handleEditClick}
            >
              {'EDIT'}
            </Button>
          )}
          {!loading && editing && (
            <Button
              variant="contained"
              className="main-button"
              disabled={saving}
              onClick={saveLocation}
            >
              {saving && <ButtonSpinner />}
              {locationId ? 'SAVE & RETURN' : 'SAVE & CONTINUE'}
            </Button>
          )}
          {Boolean(locationId) && (
            <Button
              className="cancel-button"
              component={Link}
              to={NAVIGATION.MAIN}
              disabled={saving}
            >
              {'Cancel'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

LocationInfo.propTypes = {
  currentLocations: PropTypes.array,
  setLocations: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentLocations: appSelectors.currentLocations(state),
});

const mapDispatchToProps = (dispatch) => ({
  setLocations: (locations) => dispatch(appActions.setLocations(locations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationInfo);
