import { createReducer } from 'service/utility';

import { AUTH_LOGGED_IN_SUCCESS, AUTH_LOGGED_OUT_SUCCESS } from '../actions/authActions';

const initialState = {
  isLogged: false,
  currentUserUID: null,
  currentUserEmail: '',
};

export const currentUser = createReducer(initialState, {
  [AUTH_LOGGED_IN_SUCCESS](state, { user }) {
    return {
      ...state,
      isLogged: true,
      currentUserUID: user.uid,
      currentUserEmail: user.email,
    };
  },

  [AUTH_LOGGED_OUT_SUCCESS](state) {
    return {
      ...state,
      isLogged: false,
      currentUserUID: null,
      currentUserEmail: null,
    };
  },
});
