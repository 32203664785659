export const APP_NAME = 'PeopleCount';

const APP_CONFIG = {
  localhost: {
    apiRoot: 'https://ryt7pxlc5h.execute-api.us-east-1.amazonaws.com/testing/',
    liveRoot: 'https://frontend.app.pc-testing.tilefive.com',
    firebase: {
      apiKey: "AIzaSyB70in-wUc8qPVzDMyy3kQU8Wkkx-G3ds4",
      authDomain: "peoplecount-e360c.firebaseapp.com",
      databaseURL: "https://peoplecount-e360c.firebaseio.com",
      projectId: "peoplecount-e360c",
      storageBucket: "peoplecount-e360c.appspot.com",
      messagingSenderId: "195052648063",
      appId: "1:195052648063:web:7c41a1840624732402f14a",
      measurementId: "G-H3FM2YE0XZ"
    },
  },
  testing: {
    apiRoot: 'https://ryt7pxlc5h.execute-api.us-east-1.amazonaws.com/testing/',
    liveRoot: 'https://frontend.app.pc-testing.tilefive.com',
    firebase: {
      apiKey: "AIzaSyB70in-wUc8qPVzDMyy3kQU8Wkkx-G3ds4",
      authDomain: "peoplecount-e360c.firebaseapp.com",
      databaseURL: "https://peoplecount-e360c.firebaseio.com",
      projectId: "peoplecount-e360c",
      storageBucket: "peoplecount-e360c.appspot.com",
      messagingSenderId: "195052648063",
      appId: "1:195052648063:web:7c41a1840624732402f14a",
      measurementId: "G-H3FM2YE0XZ"
    },
  },
  staging: {
    apiRoot: 'https://fwv92hcknl.execute-api.us-east-1.amazonaws.com/staging/',
    liveRoot: 'https://www.app.pc-staging.tilefive.com',
    firebase: {
      apiKey: "AIzaSyB70in-wUc8qPVzDMyy3kQU8Wkkx-G3ds4",
      authDomain: "peoplecount-e360c.firebaseapp.com",
      databaseURL: "https://peoplecount-e360c.firebaseio.com",
      projectId: "peoplecount-e360c",
      storageBucket: "peoplecount-e360c.appspot.com",
      messagingSenderId: "195052648063",
      appId: "1:195052648063:web:7c41a1840624732402f14a",
      measurementId: "G-H3FM2YE0XZ"
    },
  },
  production: {
    apiRoot: 'https://3jvi3266bd.execute-api.us-east-1.amazonaws.com/prod/',
    liveRoot: 'https://www.peoplecount.live',
    firebase: {
      apiKey: "AIzaSyB70in-wUc8qPVzDMyy3kQU8Wkkx-G3ds4",
      authDomain: "peoplecount-e360c.firebaseapp.com",
      databaseURL: "https://peoplecount-e360c.firebaseio.com",
      projectId: "peoplecount-e360c",
      storageBucket: "peoplecount-e360c.appspot.com",
      messagingSenderId: "195052648063",
      appId: "1:195052648063:web:7c41a1840624732402f14a",
      measurementId: "G-H3FM2YE0XZ"
    },
  },
};

const getAppConfig = () => {
  const env = (process.env.REACT_APP_ENV) ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

  if (env === 'production') return APP_CONFIG.production;
  if (env === 'test' || env === 'staging') return APP_CONFIG.staging;
  if (env === 'development' || env === 'testing') return APP_CONFIG.testing;

  return APP_CONFIG.localhost;
};

const AppConfig = getAppConfig();

export default AppConfig;
