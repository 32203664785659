import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import AppConfig from 'config/app';


const getFirebaseApp = () => {
  if (!firebase.apps.length) {
    return firebase.initializeApp(AppConfig.firebase);
  }

  return firebase.app();
};

const firebaseApp = getFirebaseApp();

export const firestore = firebaseApp.firestore();

export const signUp = async (email, password) => {
  try {
    const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);

    return { userCredential };
  } catch (e) {
    const error = {
      code: e.code,
      message: 'There was a problem creating the account. Please try again.',
    };

    if (error.code === 'auth/email-already-in-use') {
      error.message = 'An account with this email already exists. Please choose a different one.';
    } else if (error.code === 'auth/invalid-email') {
      error.message = 'The email address is not valid.';
    } else if (error.code === 'auth/weak-password') {
      error.message = 'The password is not strong enough.';
    }

    console.log(`There was an error (code ${error.code}) : "${error.message}"`);

    return { error };
  }
};

export const signIn = async (email, password) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);

    const currentUser = firebase.auth().currentUser;

    return { currentUser };
  } catch (e) {
    const error = {
      code: e.code,
      message: 'There was a problem signing in. Please try again.',
    };

    if (error.code === 'auth/invalid-email') {
      error.message = 'The email address is not valid.';
    } else if (error.code === 'auth/user-disabled') {
      error.message = 'The account with this email address has been disabled.';
    } else if (error.code === 'auth/user-not-found') {
      error.message = 'An account with this email does not exist. Please choose a different one.';
    } else if (error.code === 'auth/wrong-password') {
      error.message = 'Incorrect password. Please try again.';
    }

    console.log(`There was an error (code ${error.code}) : "${error.message}"`);

    return { error };
  }
};

export const resetPassword = async (email) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);

    return { success: true };
  } catch (e) {
    const error = {
      code: e.code,
      message: 'There was a problem signing in. Please try again.',
    };

    if (error.code === 'auth/invalid-email') {
      error.message = 'The email address is not valid.';
    } else if (error.code === 'auth/missing-continue-uri') {
      error.message = 'A continue URL must be provided in the request.';
    } else if (error.code === 'auth/invalid-continue-uri') {
      error.message = 'The continue URL provided in the request is invalid.';
    } else if (error.code === 'auth/unauthorized-continue-uri') {
      error.message = 'The domain of the continue URL is not whitelisted.';
    } else if (error.code === 'auth/user-not-found') {
      error.message = 'An account with this email does not exist. Please choose a different one.';
    }

    console.log(`There was an error (code ${error.code}) : "${error.message}"`);

    return { error };
  }
};

export const confirmPasswordReset = async (code, newPassword) => {
  try {
    await firebase.auth().confirmPasswordReset(code, newPassword);

    return { success: true };
  } catch (e) {
    const error = {
      code: e.code,
      message: 'There was a problem signing in. Please try again.',
    };

    if (error.code === 'auth/expired-action-code') {
      error.message = 'The password reset code has expired. Please try again.';
    } else if (error.code === 'auth/invalid-action-code') {
      error.message = 'The password reset code is invalid.';
    } else if (error.code === 'auth/user-disabled') {
      error.message = 'The account with this email address has been disabled.';
    } else if (error.code === 'auth/user-not-found') {
      error.message = 'An account with this email does not exist. Please choose a different one.';
    } else if (error.code === 'auth/weak-password') {
      error.message = 'The new password is not strong enough. Please choose a different one.';
    }

    console.log(`There was an error (code ${error.code}) : "${error.message}"`);

    return { error };
  }
};

export const signOut = async () => {
  try {
    await firebase.auth().signOut();

    return true;
  } catch (error) {
    console.log(`There was an error (code ${error.code}) : "${error.message}"`);

    return false;
  }
};

export const sendVerificationEmail = async () => {
  // const actionCodeSettings = {
  //   url: 'https://www.example.com/?email=' + firebase.auth().currentUser.email,
  //   iOS: {
  //     bundleId: 'com.example.ios'
  //   },
  //   android: {
  //     packageName: 'com.example.android',
  //     installApp: true,
  //     minimumVersion: '12'
  //   },
  //   handleCodeInApp: true,
  // };

  try {
    await firebase.auth().currentUser.sendEmailVerification(/*actionCodeSettings*/)

    return true;
  } catch (error) {
    console.log(`There was an error (code ${error.code}) : "${error.message}"`);

    return false;
  }
};

export const getIdToken = async () => {
  const token = await firebase.auth().currentUser.getIdToken();

  return token;
};
