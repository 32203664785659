import React from 'react';
import { PropTypes } from 'prop-types';
import Typography from '@material-ui/core/Typography';


const ViewField = (props) => (
  <div className="view-field">
    <Typography
      variant="body1"
      component="span"
      className="fw-bold"
    >
      {`${props.label}: `}
    </Typography>
    <Typography
      variant="body1"
      component="span"
    >
      {props.value === null || props.value === '' || typeof props.value === 'undefined' ? '--' : props.value}
    </Typography>
  </div>
);

ViewField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};


export default ViewField;
