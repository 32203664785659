import React, { PureComponent } from 'react';
import { SvgIcon } from '@material-ui/core';

export default class CrownIcon extends PureComponent {
  render() {
    return (
      <SvgIcon
        viewBox="0 0 24 24"
        fontSize="inherit"
      >
        <path d="M12 8L15 13.2L18 10.5L17.3 14H6.7L6 10.5L9 13.2L12 8M12 4L8.5 10L3 5L5 16H19L21 5L15.5 10L12 4M19 18H5V19C5 19.6 5.4 20 6 20H18C18.6 20 19 19.6 19 19V18Z" />
      </SvgIcon>
    );
  }
}
