import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Paper, IconButton, CircularProgress, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import CrownIcon from 'assets/CrownIcon';
import { HamburgerButton, HamburgerMenu } from 'common/hamburger';
import * as NAVIGATION from 'config/navigation';


const Counter = (props) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const maxCount = props.counter.maxCount ? Number(props.counter.maxCount) : null;
  const decrementDisabled = !props.count;
  const incrementDisabled = (maxCount === null ? false : props.count === maxCount) || props.disableIncrement;
  const occ = !props.count || !maxCount ? null : props.count/maxCount*100;
  const hasProgressBar = occ !== null;

  const handleEditCounter = () => {
    hideMenu();
    history.push(`${NAVIGATION.COUNTER_INFO}/${props.counter.id}`);
  };

  const handleResetCounter = () => {
    hideMenu();
    props.onReset();
  };

  const handleResetAll = () => {
    hideMenu();
    props.onResetAll();
  };

  const handleDeactivate = () => {
    hideMenu();
    props.onDeactivate();
  };

  const handleActivate = () => {
    hideMenu();
    props.onActivate();
  };

  const showMenu = (event) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const hideMenu = () => {
    setAnchorEl(null);
  };


  const isMasterCounter = !props.counter.id;
  const isActive = props.counter.isActive !== 0;

  return (
    <Paper
      className={classnames(
        'counter',
        'shadow-lg',
        {
          'has-progress-bar': hasProgressBar,
        },
      )}
    >
      <div className="name-container">
        {isMasterCounter && (
          <CrownIcon />
        )}
        <span className="counter-name">
          {props.counter.name}
        </span>
      </div>
      <div className="hamburger-container">
        <HamburgerButton
          isActive={Boolean(anchorEl)}
          onClick={showMenu}
        />
        <HamburgerMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={hideMenu}
        >
          {!isMasterCounter && (
            <MenuItem onClick={handleEditCounter}>
              {'Edit'}
            </MenuItem>
          )}
          {!isMasterCounter && (
            <MenuItem onClick={handleResetCounter}>
              {'Reset Counter'}
            </MenuItem>
          )}
          {isMasterCounter && (
            <MenuItem onClick={handleResetAll}>
              {'Reset All Counters'}
            </MenuItem>
          )}
          {!isMasterCounter && isActive && (
            <MenuItem onClick={handleDeactivate}>
              {'Deactivate'}
            </MenuItem>
          )}
          {!isMasterCounter && !isActive && (
            <MenuItem onClick={handleActivate}>
              {'Activate'}
            </MenuItem>
          )}
        </HamburgerMenu>
      </div>
      <div className="inner-container">
        <div
          className={classnames(
            'icon-container',
            'decrement',
            {
              'disabled': decrementDisabled,
            },
          )}
        >
          <IconButton
            className="icon-button"
            onClick={props.onDecrement}
            disabled={decrementDisabled}
          >
            <RemoveIcon fontSize="inherit" />
          </IconButton>
        </div>
        {props.count === null ? (
          <div className="spinner-wrapper">
            <CircularProgress
              size={36}
              color="inherit"
            />
          </div>
        ) : (
          <div className="counter-container">
            <div className="counter-input-container">
              <span
                className={classnames(
                  'counter-text',
                  {
                    'five-digits': props.count >= 10000,
                    'four-digits': props.count >= 1000 && props.count < 10000,
                    'three-digits': props.count >= 100 && props.count < 1000,
                  },
                )}
              >
                {props.count}
              </span>
            </div>
          </div>
        )}
        <div
          className={classnames(
            'icon-container',
            'increment',
            {
              'disabled': incrementDisabled,
            },
          )}
        >
          <IconButton
            className="icon-button"
            onClick={props.onIncrement}
            disabled={incrementDisabled}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
        </div>
      </div>
      {hasProgressBar && (
        <div className="progress w-100">
          <div
            className={classnames(
              'progress-bar',
              {
                'progress-3': occ >= 95,
                'progress-2': occ >= 90 && occ < 95,
                'progress-1' : occ >= 80 && occ < 90,
              },
            )}
            style={{ width: `${occ}%` }}
          >
            {occ >= 20 && (
              `${props.count}/${maxCount}`
            )}
          </div>
        </div>
      )}
    </Paper>
  );
};

Counter.propTypes = {
  count: PropTypes.number,
  counter: PropTypes.object.isRequired,
  disableIncrement: PropTypes.bool,
  onActivate: PropTypes.func,
  onDeactivate: PropTypes.func,
  onDecrement: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onResetAll: PropTypes.func,
};


export default Counter;