import { combineReducers } from 'redux';

import account from './account';
import locations from './locations';
import serviceWorker from './serviceWorker';


export default combineReducers({
  account,
  locations,
  serviceWorker,
});
