import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

import { isRequired } from 'service/utility/errorMessages';

import RootField from './RootField';


class TextField extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    multiline: PropTypes.bool,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    rows: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    validate: PropTypes.func,
    value: PropTypes.string,
  };

  static defaultProps = {
    autoFocus: false,
    error: '',
    fullWidth: false,
    label: '',
    multiline: false,
    required: false,
    value: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  };

  componentWillUnmount = () => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  };

  validateSelf = (value) => {
    const { required, label, validate } = this.props;

    if (!value && required) return isRequired(label);

    return validate ? validate(value) : null;
  };

  handleLeaveFocus = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue || '';
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleChangeSelf = (value) => {
    const { onChange } = this.props;
    const error = this.validateSelf(value);

    if (onChange) {
      onChange({ value, error });
    }
  };

  render() {
    const { error, onChange, multiline, validate, value: rawValue, className, ...rest } = this.props;
    const value = rawValue || '';
    const { blurred } = this.state;

    return (
      <RootField
        value={value}
        error={blurred && Boolean(error)}
        helperText={error}
        onChange={(e) => this.handleChangeSelf(e.target.value)}
        onBlur={this.handleLeaveFocus}
        className={classnames('text-field', { 'multiline-field': multiline, [className]: className })}
        multiline={multiline}
        {...rest}
      />
    );
  }
}


export default TextField;
