import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { loadState } from 'service/storage';

import initSagas from './initSagas';
import { appReducer, authReducer } from './reducers';


const getStore = () => {
  const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
  });

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer,
    loadState(),
    applyMiddleware(sagaMiddleware),
  );

  initSagas(sagaMiddleware);

  return store;
};


export default getStore;
