import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { appSelectors } from 'app/redux';
import { PicklistField } from 'common/formFields';
import { TIMEZONE_PICKLIST_VALUES } from 'config/timezones';
import { getLocationTimezone } from 'service/i18n';


const TimezoneField = (props) => {
  const { currentLocations, dispatch, ...rest } = props;
  const currentLocation = currentLocations && currentLocations[0];
  const currentLocationTimezone = getLocationTimezone(currentLocation);

  if (currentLocationTimezone && !rest.value) {
    props.onChange({ value: currentLocationTimezone });

    return null;
  }

  return (
    <PicklistField
      {...rest}
      picklistValues={TIMEZONE_PICKLIST_VALUES}
    />
  );
};

TimezoneField.propTypes = {
  currentLocations: PropTypes.array,
  dispatch: PropTypes.func,
  onChange: PropTypes.func,
};

TimezoneField.defaultProps = {
  value: '',
};


const mapStateToProps = (state) => ({
  currentLocations: appSelectors.currentLocations(state),
});

export default connect(mapStateToProps)(TimezoneField);
