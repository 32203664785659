import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { MenuItem } from '@material-ui/core';

import { isRequired } from 'service/utility/errorMessages';

import RootField from './RootField';


class PicklistField extends Component {
  static propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    picklistValues: PropTypes.array.isRequired,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  };

  static defaultProps = {
    error: '',
    fullWidth: false,
    label: '',
    required: false,
    value: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue === null ? '' : rawValue;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.required !== this.props.required || prevProps.value !== this.props.value) {
      const { value: rawValue, onChange } = this.props;
      const value = rawValue === null ? '' : rawValue;
      const error = this.validateSelf(value);

      if (onChange) {
        onChange({ error });
      }
    }
  };

  componentWillUnmount = () => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  };

  validateSelf = (value) => {
    const { required, label } = this.props;

    if ((value === '' || value === null) && required) return isRequired(label);

    return null;
  };

  handleLeaveFocus = () => {
    const { value: rawValue, onChange } = this.props;
    const value = rawValue === null ? '' : rawValue;
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ error });
    }
  };

  handleChangeSelf = (value) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ value });
    }
  };

  render() {
    const { className, error, onChange, picklistValues, value: rawValue, ...rest } = this.props;
    const value = rawValue === null ? '' : rawValue;
    const { blurred } = this.state;

    return (
      <RootField
        select
        value={value}
        error={blurred && Boolean(error)}
        helperText={error}
        onChange={(e) => this.handleChangeSelf(e.target.value)}
        onBlur={this.handleLeaveFocus}
        className={classnames('picklist-field', { [className]: Boolean(className) })}
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
        children={
          picklistValues.map((picklistValue) => (
            <MenuItem
              key={picklistValue.value}
              value={picklistValue.value}
            >
              {picklistValue.label}
            </MenuItem>
          ))
        }
      />
    );
  }
}


export default PicklistField;
