import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Drawer, AppBar, Toolbar, IconButton, Hidden } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { appSelectors, authSelectors } from 'app/redux';

import { DrawerContent/*, LocationSelector*/, SignOut } from './components';


const Layout = (props) => {
  const [isSidenavOpen, setIsSidenavOpen] = React.useState(false);

  const closeDrawer = () => {
    setIsSidenavOpen(false);
  };

  const handleDrawerToggle = () => {
    setIsSidenavOpen((prevIsSidenavOpen) => !prevIsSidenavOpen);
  };


  const currentLocationName = props.currentLocations && props.currentLocations[0] && props.currentLocations[0].name;
  const { isLogged } = props.currentUser;

  return (
    <React.Fragment>
      {isLogged && (
        <React.Fragment>
          <AppBar
            id="header"
            position="fixed"
          >
            <Toolbar className="header-toolbar">
              <div className="header-section header-left">
                <IconButton
                  color="inherit"
                  onClick={handleDrawerToggle}
                  className="header-button hamburger-button"
                >
                  <MenuIcon />
                </IconButton>
              </div>
              <div className="header-section header-center">
                <h1 className="header-title">
                  {currentLocationName}
                </h1>
              </div>
              <div className="header-section header-right">
                {/*<LocationSelector />*/}
                <SignOut />
              </div>
            </Toolbar>
          </AppBar>
          <nav id="nav">
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                open={isSidenavOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true,
                }}
                classes={{
                  paper: "drawer-paper",
                }}
              >
                <DrawerContent onItemClick={closeDrawer} />
              </Drawer>
            </Hidden>
            <Hidden smDown>
              <Drawer
                variant="permanent"
                open
                classes={{
                  paper: "drawer-paper",
                }}
              >
                <DrawerContent onItemClick={closeDrawer} />
              </Drawer>
            </Hidden>
          </nav>
        </React.Fragment>
      )}
      <main id="content">
        {props.children}
      </main>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  currentLocations: PropTypes.array,
  currentUser: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
  currentLocations: appSelectors.currentLocations(state),
  currentUser: authSelectors.currentUser(state),
});

export default connect(mapStateToProps)(Layout);
