import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormControl, FormLabel, FormControlLabel, Checkbox, RadioGroup, Radio, Button } from '@material-ui/core';

import { appSelectors } from 'app/redux';
import { TextField, NumberField } from 'common/formFields';
import { Spinner, ButtonSpinner } from 'common/statusIndicators';
import * as NAVIGATION from 'config/navigation';
import * as crud from 'service/crud';
import { useEffectAsync } from 'service/utility';


const TYPES = [
  { label: 'Increments Master Counter', _value: 1, value: 0 },
  { label: 'Does Not Increment Master Counter', _value: 0, value: 1 },
];


const CounterInfo = (props) => {
  const initialState = {
    name: null,
    description: null,
    maxCount: null,
    displayTypeIdx: 0,
    isPublic: true,
    isActive: true,
    deviceKey: null,
  };

  const { counterId } = props.match.params;
  const currentLocation = props.currentLocations && props.currentLocations[0];
  const locationId = currentLocation ? currentLocation.id : null;


  const history = useHistory();

  const [loading, setLoading] = React.useState(true);
  const [name, setName] = React.useState(initialState.name);
  const [description, setDescription] = React.useState(initialState.description);
  const [maxCount, setMaxCount] = React.useState(initialState.maxCount);
  const [displayTypeIdx, setDisplayTypeIdx] = React.useState(initialState.displayTypeIdx);
  const [isPublic, setIsPublic] = React.useState(initialState.isPublic);
  const [isActive, setIsActive] = React.useState(initialState.isActive);
  const [deviceKey, setDeviceKey] = React.useState(initialState.deviceKey);
  const [validationErrors, setValidationErrors] = React.useState({});
  const [saving, setSaving] = React.useState(false);


  const afterCounterIsRetrieved = async (counter) => {
    const counterIncrementParent = TYPES.find((e) => e._value === counter.incrementParent);
    
    setName(counter.name || initialState.name);
    setDescription(counter.description || initialState.description);
    setMaxCount(counter.maxCount || initialState.maxCount);
    setIsPublic(Boolean(counter.isPublic));
    setIsActive(counter.isActive === 0 ? false : true);
    setDisplayTypeIdx(counterIncrementParent ? counterIncrementParent.value : initialState.displayTypeIdx);
    setDeviceKey(counter.deviceKey || initialState.deviceKey);

    setLoading(false);
  };

  const loadCounter = async () => {
    if (!counterId) {
      setName(initialState.name);
      setDescription(initialState.description);
      setMaxCount(initialState.maxCount);
      setDisplayTypeIdx(initialState.displayTypeIdx);
      setIsPublic(initialState.isPublic);
      setIsActive(initialState.isActive);
      setDeviceKey(initialState.deviceKey);

      setLoading(false);
    } else {
      await crud.getCounter({
        locationId,
        counterId,
        onSuccess: afterCounterIsRetrieved,
        onError: handleError,
      });
    }
  };

  useEffectAsync(loadCounter, [counterId]);


  const handleError = (error) => {
    setLoading(false);
    setSaving(false);

    console.log('CounterInfo error: ');
    console.log(error);

    toast.error(error);
  }


  const finish = async () => {
    setSaving(false);
    history.push(NAVIGATION.COUNTERS);
  };

  const saveCounter = async () => {
    setSaving(true);

    const counter = {
      name,
      description,
      maxCount,
      incrementParent: TYPES.find((e) => e.value === displayTypeIdx)._value,
      isPublic: isPublic ? 1 : 0,
      isActive: isActive ? 1 : 0,
      deviceKey,
    };

    if (counterId) {
      counter.id = Number(counterId);
    }

    await crud.upsertCounter({
      locationId,
      counter,
      onSuccess: finish,
      onError: handleError,
    });
  };


  const handleChange = (varName, setStateFunc) => (update) => {
    const newValue = update.hasOwnProperty('value');
    const newError = update.hasOwnProperty('error');

    if (newValue) {
      setStateFunc(update.value);
    }

    if (newError) {
      setValidationErrors((prevValidationErrors) => ({
        ...prevValidationErrors,
        [varName]: update.error,
      }));
    }
  };


  return (
    <div className="onboarding-page light-gradient">
      <div className="inner-container">
        <div className="form">
          <span className="inner-title">
            {counterId ? 'Edit Counter' : 'Create New Counter'}
          </span>
          {loading ? (
            <Spinner />
          ) : (
            <React.Fragment>
              {!counterId && (
                <span className="inner-secondary-title">
                  {'Let\'s create a new counter for this location.'}
                </span>
              )}
              <TextField
                label="Counter Name"
                value={name}
                error={validationErrors.name}
                onChange={handleChange('name', setName)}
                placeholder="Lobby"
                required
                fullWidth
                autoFocus
              />
              <TextField
                label="Notes"
                value={description}
                error={validationErrors.description}
                onChange={handleChange('description', setDescription)}
                placeholder="Hours of Operation: 9am-5pm"
                fullWidth
                multiline
                rows={4}
              />
              <NumberField
                label="Max Capacity"
                value={maxCount}
                error={validationErrors.maxCount}
                onChange={handleChange('maxCount', setMaxCount)}
                placeholder="999"
                fullWidth
              />
              <FormControl margin="normal">
                <FormLabel>
                  {'Type'}
                </FormLabel>
                <RadioGroup
                  value={displayTypeIdx}
                  onChange={(event) => setDisplayTypeIdx(Number(event.target.value))}
                >
                  {TYPES.map((type) => (
                    <FormControlLabel
                      key={type.value}
                      label={type.label}
                      value={type.value}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <FormControl margin="normal">
                <FormLabel>
                  {'Viewing Permissions'}
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPublic}
                      onChange={() => setIsPublic((prevIsPublic) => !prevIsPublic)}
                    />
                  }
                  label="Public"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isActive}
                      onChange={() => setIsActive((prevIsActive) => !prevIsActive)}
                    />
                  }
                  label="Active"
                />
              </FormControl>
              <TextField
                label="Device ID"
                value={deviceKey}
                error={validationErrors.deviceKey}
                onChange={handleChange('deviceKey', setDeviceKey)}
                placeholder="a1b2c3d4"
                fullWidth
              />
            </React.Fragment>
          )}
        </div>
        <div className="button-wrapper">
          {!loading && (
            <Button
              variant="contained"
              className="main-button"
              disabled={saving}
              onClick={saveCounter}
            >
              {saving && <ButtonSpinner />}
              {counterId ? 'SAVE & RETURN' : 'SAVE & CONTINUE'}
            </Button>
          )}
          <Button
            className="cancel-button"
            component={Link}
            to={NAVIGATION.COUNTERS}
            disabled={saving}
          >
            {'Cancel'}
          </Button>
        </div>
      </div>
    </div>
  );
};

CounterInfo.propTypes = {
  currentLocations: PropTypes.array,
  match: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => ({
  currentLocations: appSelectors.currentLocations(state),
});

export default connect(mapStateToProps)(CounterInfo);

