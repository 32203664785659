import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';

import { appActions, authActions } from 'app/redux';
import LOGO from 'assets/pc-squircle-1024-transparent.png';
import { PasswordField, EmailField } from 'common/formFields';
import { ButtonSpinner } from 'common/statusIndicators';
import * as NAVIGATION from 'config/navigation';
import * as crud from 'service/crud';
import * as authService from 'service/firebase';


const SignUp = (props) => {
  const history = useHistory();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [validationErrors, setValidationErrors] = React.useState({});
  const [saving, setSaving] = React.useState(false);


  const handleError = (error) => {
    setSaving(false);

    console.log('SignUp error: ');
    console.log(error);

    toast.error(error);
  };


  const finishSignUp = async () => {
    setSaving(false);
    history.push(NAVIGATION.LOCATION_INFO);
  };

  const afterAccountIsRetrieved = async (account) => {
    // await storage.setItem('account', JSON.stringify(account));
    props.setAccount(account);

    await finishSignUp();
  };

  const afterAccountIsCreated = async () => {
    await crud.getAccount({
      onSuccess: afterAccountIsRetrieved,
      onError: handleError,
    });
  };

  const afterUserIsCreated = async (user) => {
    // const userUID = user.uid;
    // await storage.setItem('userUID', JSON.stringify(userUID));

    await crud.createAccount({
      newAccount: { email },
      onSuccess: afterAccountIsCreated,
      onError: handleError,
    });
  };

  const handleChange = (varName, setStateFunc) => (update) => {
    const newValue = update.hasOwnProperty('value');
    const newError = update.hasOwnProperty('error');

    if (newValue) {
      setStateFunc(update.value);
    }

    if (newError) {
      setValidationErrors((prevValidationErrors) => ({
        ...prevValidationErrors,
        [varName]: update.error,
      }));
    }
  };

  const handleSignUp = async () => {
    setSaving(true);
    const response = await authService.signUp(email, password);

    if (response.userCredential) {
      const user = response.userCredential.user;
      props.setCurrentUser(user);

      await afterUserIsCreated(user);
    } else {
      handleError(response.error.message);
    }
  };


  return (
    <div className="onboarding-page light-gradient">
      <div className="logo-title-row">
        <Link
          to={NAVIGATION.MAIN}
          className="text-decoration-none"
        >
          <img
            src={LOGO}
            className="logo"
            alt="logo"
          />
          <span className="title">
            {'People Count'}
          </span>
        </Link>
      </div>
      <div className="inner-container dark-gradient">
        <div className="form">
          <span className="inner-title">
            {'Create an Account'}
          </span>
          <span className="inner-secondary-title">
            {'Fill the form below to complete registering for the app.'}
          </span>
          <EmailField
            label="Email"
            value={email}
            error={validationErrors.email}
            onChange={handleChange('email', setEmail)}
            placeholder="johndoe@mail.com"
            required
            fullWidth
            autoFocus
          />
          <PasswordField
            label="Password"
            value={password}
            error={validationErrors.password}
            onChange={handleChange('password', setPassword)}
            placeholder="········"
            required
            fullWidth
          />
        </div>
        <div className="button-wrapper">
          <Button
            variant="contained"
            className="main-button"
            disabled={saving}
            onClick={handleSignUp}
          >
            {saving && <ButtonSpinner />}
            {'SAVE & CONTINUE'}
          </Button>
          <Button
            className="secondary-button"
            component={Link}
            to={NAVIGATION.SIGNIN}
          >
            {'Already have an account?'}
          </Button>
          <Button
            className="secondary-button"
            component={Link}
            to={NAVIGATION.FORGOT_PASSWORD}
          >
            {'Forgot your password?'}
          </Button>
        </div>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  setAccount: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};


const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setAccount: (account) => dispatch(appActions.setAccount(account)),
  setCurrentUser: (user) => dispatch(authActions.setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
