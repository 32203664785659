import { put, take, call } from 'redux-saga/effects';

import * as api from 'service/api';

import { setAccount, setLocations } from '../actions/appActions';
import { authLoggedInSuccess, SET_CURRENT_USER } from '../actions/authActions';
import { setGoogleAnalyticsUser } from './googleAnalyticsSagas';


export function *setCurrentUserSaga() {
  while (true) {
    const { user } = yield take(SET_CURRENT_USER);

    const { data: account } = yield call(api.getAccount);

    yield put(setAccount(account));

    const { data: locations } = yield call(api.getLocations);

    yield put(setLocations(locations));
    yield put(authLoggedInSuccess(user));

    yield call(setGoogleAnalyticsUser);
  }
}
