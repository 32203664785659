import { createReducer } from 'service/utility';

import { appActions } from '../actions';


const initialState = [];

export default createReducer(initialState, {
  [appActions.SET_LOCATIONS]: (state, { locations }) => locations,
});
