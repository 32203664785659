import { createReducer } from 'service/utility';

import { appActions } from '../actions';


const initialState = {
  initialized: false,
  updated: false,
  registration: null,
};

export default createReducer(initialState, {
  [appActions.SW_INIT]: (state) => ({
    ...state,
    initialized: !state.initialized,
  }),
  [appActions.SW_UPDATE]: (state, { payload }) => ({
    ...state,
    updated: !state.updated,
    registration: payload,
  }),
});
