import React from 'react';
import { List } from '@material-ui/core';
// import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone';
// import BookmarkTwoToneIcon from '@material-ui/icons/BookmarkTwoTone';
import ContactPhoneTwoToneIcon from '@material-ui/icons/ContactPhoneTwoTone';
import InsertChartTwoToneIcon from '@material-ui/icons/InsertChartTwoTone';
import PersonAddTwoToneIcon from '@material-ui/icons/PersonAddTwoTone';
import EventTwoToneIcon from '@material-ui/icons/EventTwoTone';

import LOGO from 'assets/pc-squircle-1024-transparent.png';
import * as NAVIGATION from 'config/navigation';

import { DrawerItem } from '.';


const DrawerContent = (props) => (
  <React.Fragment>
    <img
      src={LOGO}
      className="logo"
      alt="logo"
    />
    <List>
      <DrawerItem
        path={NAVIGATION.LOCATION_INFO}
        icon={ContactPhoneTwoToneIcon}
        label="Location Info"
        onClick={props.onItemClick}
      />
      {/*<DrawerItem
        path={NAVIGATION.MAIN}
        icon={AttachMoneyTwoToneIcon}
        label="Order History"
        onClick={props.onItemClick}
      />*/}
      <DrawerItem
        path={NAVIGATION.COUNTERS}
        icon={PersonAddTwoToneIcon}
        label="Live Counters"
        onClick={props.onItemClick}
      />
      {/*<DrawerItem
        path={NAVIGATION.MAIN}
        icon={BookmarkTwoToneIcon}
        label="Offers"
        onClick={props.onItemClick}
      />*/}
      <DrawerItem
        path={NAVIGATION.MAIN}
        icon={InsertChartTwoToneIcon}
        label="Dashboard"
        onClick={props.onItemClick}
      />
      <DrawerItem
        path={NAVIGATION.HISTORY_DASHBOARD}
        icon={EventTwoToneIcon}
        label="History Dashboard"
        onClick={props.onItemClick}
      />
    </List>
  </React.Fragment>
);


export default DrawerContent;
