export const currentAccount = (state) => state.app.account;
export const currentLocations = (state) => state.app.locations;
export const serviceWorker = (state) => state.app.serviceWorker;

const appSelectors = {
  currentAccount,
  currentLocations,
  serviceWorker,
};

export default appSelectors;
