import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Route } from 'react-router-dom';

export class PrivateRouteView extends Component {
  static propTypes = {
    component: PropTypes.oneOfType([
      PropTypes.func.isRequired,
      PropTypes.object.isRequired,
    ]),
  };

  render() {
    const { component: Comp, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => (
          rest.currentUser.isLogged ? (
            <Comp {...props} />
          ) : null
        )}
      />
    );
  }
}
