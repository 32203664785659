import { put, take, call } from 'redux-saga/effects';

import * as authService from 'service/firebase';

import { LOG_OUT_USER, authLoggedOutSuccess } from '../actions/authActions';
import { setGoogleAnalyticsUser } from './googleAnalyticsSagas';


export function *logOutSaga() {
  while (true) {
    yield take(LOG_OUT_USER);

    try {
      yield call(authService.signOut);
      yield put(authLoggedOutSuccess());

      yield call(setGoogleAnalyticsUser);
    } catch (error) {
      console.log('authService.signOut error: ', error);
    }
  }
}

