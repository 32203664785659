import React from 'react';
import { Paper } from '@material-ui/core';


const CompositeChartTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    const { payload: { netCount, incomingCount, outgoingCount } } = payload[0];

    return (
      <Paper className="chart-tooltip">
        <p>
          <span className="fw-bold">
            {netCount}
          </span>
          <span className="tooltip-text">
            {' total visitors'}
          </span>
        </p>
        <React.Fragment>
          <p className="small">
            {'From last hour'}
          </p>
          <p className="tooltip-text">
            {'New visitors: '}
            <span className="fw-bold">
              {incomingCount}
            </span>
          </p>
          <p className="tooltip-text">
            {'Visitors exited: '}
            <span className="fw-bold">
              {outgoingCount}
            </span>
          </p>
        </React.Fragment>
      </Paper>
    );
  }

  return null;
};


export default CompositeChartTooltip;
