import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import firebase from 'firebase/app';
import 'firebase/auth';

import { authActions, authSelectors } from 'app/redux';
import * as NAVIGATION from 'config/navigation';

import { PrivateRouteView } from './PrivateRouteView';


class PrivateRoute extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setCurrentUser: PropTypes.func.isRequired,
  };

  checkAuthorization = () => true;

  checkAuthentication = () => {
    const { currentUser, setCurrentUser, history } = this.props;

    if (currentUser.isLogged) {
      return true;
    }
    let cbActive = true;

    firebase.auth().onAuthStateChanged((user) => {
      if (cbActive) {
        if (user) {
          setCurrentUser(user);
          cbActive = false;
        } else {
          history.push(NAVIGATION.SIGNIN);
          cbActive = false;
        }
      }
    });


    return false;
  };

  getAccess = () => this.checkAuthentication() && this.checkAuthorization();

  render() {
    return (
      <PrivateRouteView
        {...this.props}
        hasAccess={this.getAccess()}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: authSelectors.currentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(authActions.setCurrentUser(user)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
