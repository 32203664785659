import { APP_NAME } from 'config/app';

const getKeyName = (key) => `@${APP_NAME}_${key}`;

const stateKey = getKeyName('state');


export const loadState = () => {
  const serializedState = localStorage.getItem(stateKey);
  const state = serializedState ? JSON.parse(serializedState) : {};

  return state;
};

export const saveState = (state) => {
  const serializedState = JSON.stringify({
    auth: {
      currentUser: {
        currentUserUID: state.auth.currentUser.currentUserUID,
        currentUserEmail: state.auth.currentUser.currentUserEmail,
        imageURL: state.auth.currentUser.imageURL,
      },
    },
  });

  localStorage.setItem(stateKey, serializedState);
};
