import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import ReorderIcon from '@material-ui/icons/Reorder';
import TodayIcon from '@material-ui/icons/Today';

import { appSelectors } from 'app/redux';
import { Spinner } from 'common/statusIndicators';
import { CHART_TYPES_STRING } from 'config/charts';
import * as API from 'service/api';
import { getLocationTimezone } from 'service/i18n';
import { useEffectAsync } from 'service/utility';
import * as timeUtils from 'service/utility/dateTime';

import { Chart, SimpleChartTooltip, CompositeChartTooltip } from './components';


const Dashboard = (props) => {
  const currentLocation = props.currentLocations && props.currentLocations[0];
  const timeZone = getLocationTimezone(currentLocation);
  const locationId = currentLocation ? currentLocation.id : null;

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);

  const loadCharts = async () => {
    if (locationId) {
      const startDT = timeUtils.iso8601MidnightTodayInUTC(timeZone);
      const endDT = timeUtils.iso8601EodTodayInUTC(timeZone);

      const { data: data2 } = await API.getCharts(
        `startDT=${startDT}&endDT=${endDT}&locationId=${locationId}&types=${CHART_TYPES_STRING}`
      );

      const data3 = {
        dailyAvg: Math.round(data2.dailyAvg.count),
        dailyHigh: data2.dailyHigh.count,
        dailyTraffic: data2.dailyTraffic.count,
        hourly: Array.from(new Array(data2.hourlyCount.length)).map((_item, idx) => ({
          hour: data2.hourlyCount[idx].hour,
          incomingCount: data2.hourlyIncoming[idx].count,
          outgoingCount: data2.hourlyOutgoing[idx].count,
          netCount: data2.hourlyCount[idx].count + (data2.hourlyIncoming[idx].count - data2.hourlyOutgoing[idx].count),
          hourlyCount: data2.hourlyCount[idx].count,
        })),
      };
      setData(data3);
    }

    setLoading(false);
  };

  useEffectAsync(loadCharts, []);


  if (!locationId) return null;

  return (
    <div className="dashboard-page light-gradient">
      {loading ? (
        <Spinner />
      ) : !data ? (
        null
      ) : (
        <React.Fragment>
          <div className="row g-0 align-items-center">
            <div className="col-12 d-lg-flex align-items-center">
              <h1 className="p-4">Today's Traffic</h1>
              <h5 className="p-4"><TodayIcon fontSize="inherit" /> {timeUtils.todayDatetime(timeZone)}</h5>
            </div>
            {/* <div className="col-lg-6 text-right">
              Current people count:
            </div> */}
          </div>
          {/* {data.hourly.length > 0 ? ( */}
            <div className="row g-0">
            <div className="col-xs-12 col-xl-6">
              <Chart
                title="Incoming by Hour"
                data={data.hourly}
                xAxisDataKey="hour"
                yAxis={[
                  {
                    dataKey: 'incomingCount',
                    name: 'Incoming Count',
                    color: '#36C1F3',
                  },
                ]}
                tooltipComponent={<SimpleChartTooltip />}
              />
            </div>
            <div className="col-xs-12 col-xl-6">
              <Chart
                title="Outgoing by Hour"
                data={data.hourly}
                xAxisDataKey="hour"
                yAxis={[
                  {
                    dataKey: 'outgoingCount',
                    name: 'Outgoing Count',
                    color: '#F336C1',
                  },
                ]}
                tooltipComponent={<SimpleChartTooltip />}
              />
            </div>
          {/* </div>
          <div className="row g-0"> */}
            <div className="col-xs-12 col-xl-6">
              <Chart
                title="Number of people in the building by Hour"
                data={data.hourly}
                xAxisDataKey="hour"
                yAxis={[
                  {
                    dataKey: 'netCount',
                    name: 'Net Count',
                    color: '#C1F336',
                  },
                ]}
                tooltipComponent={<CompositeChartTooltip />}
              />
            </div>
            <div className="col-xs-12 col-xl-6">
              <Chart
                title="Composite Hourly Traffic"
                data={data.hourly}
                xAxisDataKey="hour"
                yAxis={[
                  {
                    dataKey: 'incomingCount',
                    name: 'Incoming Count',
                    color: '#36C1F3',
                  },
                  {
                    dataKey: 'outgoingCount',
                    name: 'Outgoing Count',
                    color: '#F336C1',
                  },
                  {
                    dataKey: 'netCount',
                    name: 'Net Count',
                    color: '#C1F336',
                  },
                ]}
                tooltipComponent={<CompositeChartTooltip />}
              />
            </div>
          </div>
          {/* ) : (
            <div className="row g-0">
              <div className="col-12">
                <h3 className="p-5">There is no data to show yet, please use your counters</h3>
              </div>
            </div>
          )} */}


          {/* <Chart
            title="Hourly People Count"
            data={data.hourly}
            xAxisDataKey="hour"
            yAxis={[
              {
                dataKey: 'hourlyCount',
                name: 'Hourly Count',
                color: '#3662F3',
              },
            ]}
            tooltipComponent={<SimpleChartTooltip />}
          /> */}
          <Paper
            elevation={0}
            className="paper-container"
          >
            <List className="stats-list">
              <ListItem className="stat-list-item">
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    className="stat-icon"
                  >
                    <ArrowUpwardIcon fontSize="inherit" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Highest # of people in building today"
                  secondary={data.dailyHigh}
                  classes={{
                    primary: 'stat-primary',
                    secondary: 'stat-secondary',
                  }}
                />
              </ListItem>
              <ListItem className="stat-list-item">
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    className="stat-icon"
                  >
                    <ReorderIcon fontSize="inherit" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Avg. # of people in building per hour"
                  secondary={data.dailyAvg}
                  classes={{
                    primary: 'stat-primary',
                    secondary: 'stat-secondary',
                  }}
                />
              </ListItem>
              <ListItem className="stat-list-item">
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    className="stat-icon"
                  >
                    <PeopleAltOutlinedIcon fontSize="inherit" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Total # of visitors today"
                  secondary={data.dailyTraffic}
                  classes={{
                    primary: 'stat-primary',
                    secondary: 'stat-secondary',
                  }}
                />
              </ListItem>
            </List>
          </Paper>
        </React.Fragment>
      )}
    </div>
  );
};

Dashboard.propTypes = {
  currentLocations: PropTypes.array,
};


const mapStateToProps = (state) => ({
  currentLocations: appSelectors.currentLocations(state),
});

export default connect(mapStateToProps)(Dashboard);
