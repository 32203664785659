import React from 'react';
import { Paper } from '@material-ui/core';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


const HistoryChart = (props) => {
  const [opacity, setOpacity] = React.useState(props.yAxis.reduce((a, b) => {
    a[b.dataKey] = 1;

    return a;
  }, {}));


  const handleMouseEnter = ({ dataKey }) => {
    setOpacity((prevOpacity) => ({
      ...prevOpacity,
      [dataKey]: 0.5,
    }));
  };

  const handleMouseLeave = ({ dataKey }) => {
    setOpacity((prevOpacity) => ({
      ...prevOpacity,
      [dataKey]: 1,
    }));
  };


  return (
    <Paper
      elevation={0}
      className="paper-container chart"
    >
      <h2>
        {props.title}
      </h2>
      <ResponsiveContainer
        width="100%"
        height={props.yAxis.length > 1 ? 432 : 400}
      >
        <BarChart
          data={props.data}
          margin={{ left: -20, top: 30, right: 10, bottom: 10 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey={props.xAxisDataKey}
            tickLine={false}
            tickMargin={10}
            padding={{ left: 25, right: 25 }}
          />
          <YAxis
            type="number"
            scale="linear"
            axisLine={false}
            tickLine={false}
            domain={[0, (dataMax) => (Math.floor(dataMax / 10) + 1) * 10]}
          />
          <Tooltip
            content={props.tooltipComponent}
            cursor={{ strokeWidth: 2, strokeDasharray: '4 2' }}
          />
          {props.yAxis.length > 1 && (
            <Legend
              wrapperStyle={{ left: -5, bottom: -5 }}
              height={32}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          )}
          {props.yAxis.map((yAxisLine, idx) => (
            <Bar
              key={idx}
              name={yAxisLine.name}
              fill={yAxisLine.color}
              barSize={16}
              dataKey={yAxisLine.dataKey}
              stroke={yAxisLine.color}
              strokeWidth={4}
              strokeOpacity={opacity[yAxisLine.dataKey]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};


export default HistoryChart;
