import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Button, IconButton } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { authActions } from 'app/redux';


const SignOut = (props) => (
  <React.Fragment>
    <Button
      color="inherit"
      onClick={props.logOutUser}
      className="header-button sign-out-button d-none d-md-block"
    >
      {'Sign Out'}
    </Button>
    <IconButton
      color="inherit"
      onClick={props.logOutUser}
      className="header-button sign-out-button d-block d-md-none"
    >
      <ExitToAppIcon />
    </IconButton>
  </React.Fragment>
);

SignOut.propTypes = {
  logOutUser: PropTypes.func.isRequired,
};


const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  logOutUser: () => dispatch(authActions.logOutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
