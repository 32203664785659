import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MenuItem, Dialog, DialogContent, Button } from '@material-ui/core';

import { authActions, appSelectors } from 'app/redux';
import { HamburgerButton, HamburgerMenu } from 'common/hamburger';
import { Spinner } from 'common/statusIndicators';
import AppConfig from 'config/app';
import * as NAVIGATION from 'config/navigation';
import * as api from 'service/api';
import * as authService from 'service/firebase';
import { getErrorMessage } from 'service/utility';

import { Counter } from './components';


const Counters = (props) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [account, setAccount] = React.useState(null);
  const [location, setLocation] = React.useState(null);
  const [masterCounter, setMasterCounter] = React.useState(null);
  const [counters, setCounters] = React.useState(null);
  const [counterMap, setCounterMap] = React.useState(null);
  const [showShareDialog, setShowShareDialog] = React.useState(false);
  const [showDeactivatedCounters, setShowDeactivatedCounters] = React.useState(false);

  const pcCol = authService.firestore.collection('peoplecount');
  const shareUrl = account && location ? `${AppConfig.liveRoot}/${account.shortCode}/${location.shortCode}` : null;

  const updateRealTimeMasterCounter = (accountParam, locationParam) => {
    const account_ = accountParam || account;
    const location_ = locationParam || location;

    pcCol.doc(account_.shortCode).collection('locations').doc(location_.shortCode).get().then(
      (doc) => {
        const realTimeLocation = doc.data();
        
        setMasterCounter(realTimeLocation.count);
      }
    );
  };

  const updateRealTimeCounters = (accountParam, locationParam) => {
    const account_ = accountParam || account;
    const location_ = locationParam || location;

    pcCol.doc(account_.shortCode).collection('locations').doc(location_.shortCode).collection('counters').get().then(
      ({ docs }) => {
        const newCounterMap = {};

        docs.forEach((doc) => {
          const newCounter = doc.data();

          newCounterMap[String(newCounter.id)] = newCounter.count;
        });

        setCounterMap(newCounterMap);
      }
    );
  };


  React.useEffect(() => {
    const unsubscribe = {};

    const loadInfo = async () => {
      if (!props.currentAccount) return;
      if (!props.currentLocations || !props.currentLocations.length) return;

      const account_ = props.currentAccount;
      setAccount(account_);

      const location_ = props.currentLocations[0];
      setLocation(location_);

      const accountDoc = pcCol.doc(account_.shortCode);
      const locationDoc = accountDoc.collection('locations').doc(location_.shortCode);

      unsubscribe.location = locationDoc.onSnapshot(
        (doc) => setLocation(doc.data())
      );
      unsubscribe.counters = locationDoc.collection('counters').onSnapshot(
        (snapshot) => setCounters(snapshot.docs.map((doc) => doc.data()))
      );

      updateRealTimeMasterCounter(account_, location_);
      updateRealTimeCounters(account_, location_);
    };

    loadInfo();

    return () => {
      if (unsubscribe.location) unsubscribe.location();
      if (unsubscribe.counters) unsubscribe.counters();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentAccount, props.currentLocations])


  React.useEffect(() => {
    const interval = window.setInterval(() => {
      if (account && location) {
        updateRealTimeMasterCounter();
      }
    }, 5000);

    return () => {
      window.clearInterval(interval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterCounter]);

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      if (account && location) {
        updateRealTimeCounters();
      }
    }, 5000);

    return () => {
      window.clearInterval(interval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterMap]);


  const getLocationMaxCount = () => location && location.maxCount ? Number(location.maxCount) : null;

  const getRealCount = (counter) => {
    const key = String(counter.id);

    if (counterMap && counterMap.hasOwnProperty(key)) {
      return counterMap[key];
    } else {
      return null;
    }
  };

  
  const handleError = (error) => {
    console.log('Main error: ');
    console.log(error);

    toast.error(error);
  }

  const handleDecrement = async () => {
    setMasterCounter((prevCount) => prevCount - 1);

    try {
      await api.decCount(location.id);
    } catch (error) {
      handleError(`api.decCount error: ${getErrorMessage(error)}`);
      updateRealTimeMasterCounter();
    }
  };

  const handleIncrement = async () => {
    setMasterCounter((prevCount) => prevCount + 1);

    try {
      await api.incCount(location.id);
    } catch (error) {
      handleError(`api.incCount error: ${getErrorMessage(error)}`);
      updateRealTimeMasterCounter();
    }
  };

  const handleCounterDecrement = (counter) => async () => {
    const key = String(counter.id);

    if (counter.incrementParent) {
      setMasterCounter((prevCount) => prevCount - 1);
    }

    setCounterMap((prevCounterMap) => ({
      ...prevCounterMap,
      [key]: prevCounterMap[key] - 1,
    }));

    try {
      await api.decCounter(location.id, counter.id);
    } catch (error) {
      handleError(`api.decCounter error: ${getErrorMessage(error)}`);
      updateRealTimeCounters();
    }
  };

  const handleCounterIncrement = (counter) => async () => {
    const key = String(counter.id);

    if (counter.incrementParent) {
      setMasterCounter((prevCount) => prevCount + 1);
    }

    setCounterMap((prevCounterMap) => ({
      ...prevCounterMap,
      [key]: prevCounterMap[key] + 1,
    }));

    try {
      await api.incCounter(location.id, counter.id);
    } catch (error) {
      handleError(`api.incCounter error: ${getErrorMessage(error)}`);
      updateRealTimeCounters();
    }
  };

  const handleCounterReset = (counter) => async () => {
    const key = String(counter.id);

    setCounterMap((prevCounterMap) => ({
      ...prevCounterMap,
      [key]: 0,
    }));

    try {
      await api.resetCounter(location.id, counter.id);
    } catch (error) {
      handleError(`api.resetCounter error: ${getErrorMessage(error)}`);
      updateRealTimeCounters();
    }
  };

  const handleCounterDeactivate = (counter) => async () => {
    const payload = {
      id: counter.id,
      isActive: 0,
    };

    try {
      await api.upsertCounter(location.id, payload);
    } catch (error) {
      handleError(`api.upsertCounter error: ${getErrorMessage(error)}`);
    }
  };

  const handleCounterActivate = (counter) => async () => {
    const payload = {
      id: counter.id,
      isActive: 1,
    };

    try {
      await api.upsertCounter(location.id, payload);
    } catch (error) {
      handleError(`api.upsertCounter error: ${getErrorMessage(error)}`);
    }
  };

  const handleResetAllCounters = async () => {
    setMasterCounter(0);
    setCounterMap((prevCounterMap) => Object.keys(prevCounterMap).reduce((a, key) => { a[key] = 0; return a; }, {}));

    try {
      await api.resetCounters(location.id);
    } catch (error) {
      handleError(`api.resetCounters error: ${getErrorMessage(error)}`);
      updateRealTimeMasterCounter();
      updateRealTimeCounters();
    }
  };

  const handleGoToOptions = () => {
    hideMenu();
    history.push(NAVIGATION.OPTIONS);
  };

  const handleShowDeactivatedCounters = () => {
    hideMenu();
    setShowDeactivatedCounters(true);
  };

  const handleHideDeactivatedCounters = () => {
    hideMenu();
    setShowDeactivatedCounters(false);
  };

  const handleNewCounter = () => {
    hideMenu();
    history.push(NAVIGATION.COUNTER_INFO);
  };

  const handleLocationEdit = () => {
    hideMenu();
    history.push(NAVIGATION.LOCATION_INFO);
  };

  const handleShare = () => {
    hideMenu();
    setShowShareDialog(true);
  };

  const closeShareDialog = () => {
    setShowShareDialog(false);
  };

  const handleCopyClick = () => {
    toast('Link copied to clipboard.');
    closeShareDialog();
  };

  const handleSignOut = async () => {
    hideMenu();
    props.logOutUser();
  };

  const showMenu = (event) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const hideMenu = () => {
    setAnchorEl(null);
  };

  
  if (!account || !location) return null;

  const maxCount = getLocationMaxCount();
  const filteredCounters = !counters ? null : showDeactivatedCounters ? counters : counters.filter((c) => c.isActive !== 0);

  return (
    <div className="counters-page">
      <div className="hamburger-container">
        <HamburgerButton
          isActive={Boolean(anchorEl)}
          onClick={showMenu}
        />
        <HamburgerMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={hideMenu}
        >
          <MenuItem onClick={handleGoToOptions}>
            {'Options'}
          </MenuItem>
          {!showDeactivatedCounters && (
            <MenuItem onClick={handleShowDeactivatedCounters}>
              {'Show Deactivated Counters'}
            </MenuItem>
          )}
          {showDeactivatedCounters && (
            <MenuItem onClick={handleHideDeactivatedCounters}>
              {'Hide Deactivated Counters'}
            </MenuItem>
          )}
          <MenuItem onClick={handleNewCounter}>
            {'Add New Counter'}
          </MenuItem>
          <MenuItem onClick={handleLocationEdit}>
            {'Edit Location Info'}
          </MenuItem>
          <MenuItem onClick={handleShare}>
            {'Share'}
          </MenuItem>
          <MenuItem onClick={handleSignOut}>
            {'Sign Out'}
          </MenuItem>
        </HamburgerMenu>
      </div>
      <div className="counters-container">
        {filteredCounters ? (
          <React.Fragment>
            <Counter
              counter={{
                id: null,
                name: 'Master Counter',
                maxCount,
              }}
              count={masterCounter}
              onIncrement={handleIncrement}
              onDecrement={handleDecrement}
              onResetAll={handleResetAllCounters}
            />
            {filteredCounters.length > 0 && filteredCounters.map((counter, idx) => (
              <Counter
                key={idx}
                counter={counter}
                count={getRealCount(counter)}
                onIncrement={handleCounterIncrement(counter)}
                onDecrement={handleCounterDecrement(counter)}
                onReset={handleCounterReset(counter)}
                onDeactivate={handleCounterDeactivate(counter)}
                onActivate={handleCounterActivate(counter)}
                disableIncrement={Boolean(counter.incrementParent) && masterCounter === maxCount}
              />
            ))}
          </React.Fragment>
        ) : (
          <Spinner />
        )}
      </div>
      <Dialog
        open={showShareDialog}
        onClose={closeShareDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogContent className="p-3 d-flex justify-content-between align-items-center">
          <samp>
            {shareUrl}
          </samp>
          <CopyToClipboard
            text={shareUrl}
            onCopy={handleCopyClick}
          >
            <Button variant="outlined">
              {'COPY'}
            </Button>
          </CopyToClipboard>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Counters.propTypes = {
  currentAccount: PropTypes.object,
  currentLocations: PropTypes.array,
  logOutUser: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentAccount: appSelectors.currentAccount(state),
  currentLocations: appSelectors.currentLocations(state),
});

const mapDispatchToProps = (dispatch) => ({
  logOutUser: () => dispatch(authActions.logOutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Counters);
