import { DateTime } from 'luxon';
import { leftPadZero } from './validations'

export const isSameDate = (iso8601String1, iso8601String2, timeZone) => (
  getLuxon(iso8601String1, timeZone).hasSame(getLuxon(iso8601String2, timeZone), 'day')
);

export const iso8601FromDateJS = (date) => (
  DateTime.fromJSDate(date).toISO()
);

export const iso8601MidnightInUTC = (iso8601Date_) => (
  DateTime.fromISO(iso8601Date_).startOf('day').setZone('UTC').toISO()
);

export const iso8601EodInUTC = (iso8601Date_) => (
  DateTime.fromISO(iso8601Date_).endOf('day').setZone('UTC').toISO()
);

export const iso860130DaysLessInUTC = (iso8601Date_, days) => (
  DateTime.fromISO(iso8601Date_).plus({ days: -days}).toISO()
);

export const fromDateJStoUTCMidnight = (date) => (
  iso8601MidnightInUTC(iso8601FromDateJS(date))
)

export const fromDateJStoUTCEod = (date) => (
  iso8601EodInUTC(iso8601FromDateJS(date))
)

export const iso8601MidnightTodayInUTC = (timeZone) => (
  DateTime.local().setZone(timeZone).startOf('day').setZone('UTC').toISO()
);

export const iso8601MidnightLessDaysInUTC = (timeZone, days) => (
  DateTime.local().setZone(timeZone).startOf('day').setZone('UTC').plus({ days: -days}).toJSDate()
);

export const iso8601EodTodayInUTC = (timeZone) => (
  DateTime.local().setZone(timeZone).endOf('day').setZone('UTC').toISO()
);

// <<<<<<< HEAD
export const iso8601Date = (iso8601String) => iso8601String.substring(0, 10);

export const iso8601DateTime = (iso8601String) => iso8601String.substring(0, 19);

// export const iso8601Today = (timeZone) => formatIso8601Date([], timeZone);
export const iso8601Today = (timeZone) => (
  iso8601Date(DateTime.local().setZone(timeZone).toISO())
);

export const dateYearMonthDay = (date) => (
  `${date.getFullYear()}-${leftPadZero(date.getMonth() + 1, 2)}-${leftPadZero(date.getDate(), 2)}`
);

// export const localDateFromMoment = (m, timeZone) => new Date(...moment(m).tz(timeZone).toArray());
export const localDateFromIso8601String = (iso8601String, timeZone = 'local') => (
  DateTime.fromISO(iso8601DateTime(DateTime.fromISO(iso8601String, { zone: timeZone }).toISO())).toJSDate()
);

// returns a special format for the Short Date format, to be used with react-datepicker, for example MM/dd/yyyy
export const getShortDateDatePickerFormat = () => (
  DateTime.fromISO('1999-12-31').toLocaleString(DateTime.DATE_SHORT).replace(/1999/, 'yyyy').replace(/12/, 'MM').replace(/31/, 'dd')
);

// returns a mask for the Short Date format, for example ##/##/####
export const getShortDateFormat = () => (
  DateTime.fromISO('1999-12-31').toLocaleString(DateTime.DATE_SHORT).replace(/\d/g, '#')
);

// returns a placeholder for the Short Date format, for example MM/DD/YYYY
export const getShortDatePlaceholder = () => (
  DateTime.fromISO('1999-12-31').toLocaleString(DateTime.DATE_SHORT).replace(/1999/, 'YYYY').replace(/12/, 'MM').replace(/31/, 'DD')
);

// returns a mask for the Short Date format, to be used with react-number-format, for example ["M", "M", "D", "D", "Y", "Y", "Y", "Y"]
export const getShortDateMask = () => (
  getShortDatePlaceholder().replace(/[^YMD]/g, '').split('')
);

// export const isEmptyIso8601Date = (str) => str.substring(0, 10) === '0000-00-00';
export const isEmptyIso8601Date = (iso8601String) => !iso8601String || iso8601Date(iso8601String) === '0000-00-00';

// Takes a YYYY-MM-DDTHH:mm:ss DateTime string and computes a Date() in the local timezone
// export const localDateFromIso8601DateTime = (str) => new Date(...moment(str, 'YYYY-MM-DDTHH:mm:ss').toArray());
export const localDateFromIso8601DateTime = (iso8601String, timeZone = 'local') => (
  localDateFromIso8601String(iso8601DateTime(iso8601String), timeZone)
);

export const formatIso8601Date = (iso8601String, timeZone) => (
  iso8601Date(DateTime.fromISO(iso8601String).setZone(timeZone).toISO())
);


export const getLuxon = (iso8601String, timeZone) => {
  const dt1 = iso8601String ? DateTime.fromISO(iso8601String) : DateTime.local();
  const dt2 = timeZone ? dt1.setZone(timeZone) : dt1;

  return dt2;
};
export const formatShortDate = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toLocaleString(DateTime.DATE_SHORT)
);

export const isoTimeToLocaleString = (timeIso) => (
  DateTime.fromISO(timeIso).toLocaleString(DateTime.TIME_SIMPLE)
);
// =======
export const iso8601MidnightToday = (timeZone) => (
  DateTime.local().setZone(timeZone).startOf('day').toISO()
);

export const iso8601EodToday = (timeZone) => (
  DateTime.local().setZone(timeZone).endOf('day').toISO()
);

export const todayDatetime = (timeZone) => (
  DateTime.now().setZone(timeZone).toLocaleString(DateTime.DATETIME_SHORT)
);

export const iso8601TimeAddHoursAndMinutes = (iso8601TimeString, hours, minutes) => (
  iso8601Time(DateTime.fromISO(iso8601TimeString).plus({ hours, minutes }).toISO())
);

// takes a Date() and returns the HH:MM part of it. No timezone, used for DatePickers that work with a Date()
export const dateHoursAndMinutes = (date) => (
  `${leftPadZero(date.getHours(), 2)}:${leftPadZero(date.getMinutes(), 2)}`
);

// returns a special format for the Short Date format, to be used with react-datepicker, for example MM/dd/yyyy
export const getSimpleTimeDatePickerFormat = () => (
  DateTime.fromISO('1999-12-31T23:45:56').toLocaleString(DateTime.TIME_SIMPLE).replace(/PM/i, 'aa').replace(/45/, 'mm').replace(/23/, 'HH').replace(/11/, 'h')
);

// Takes a HH:mm string and computes a Date() in the local timezone (the date part is set to today)
export const localDateFromIso8601Time = (timeString) => (
  DateTime.fromFormat(timeString, 'HH:mm').toJSDate()
);

// returns a Date() corresponding to midnight today in the local timezone
export const dateMidnightToday = () => (
  new Date().setHours(0, 0, 0, 0)
);

export const iso8601Time = (iso8601String) => iso8601String.substring(11, 16);

export const iso8601Now = (timeZone) => (
  DateTime.local().setZone(timeZone).toISO()
);

export const iso8601NowTime = (timeZone) => (
  iso8601Time(iso8601Now(timeZone))
);


export const iso8601FromTimestamp = (milis) => (
  DateTime.fromMillis(milis).toISO()
);
// >>>>>>> develop
