import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { NavLink } from 'react-router-dom';


const renderLink = React.forwardRef((props, ref) => (
  <NavLink
    exact
    {...props}
    activeClassName="active-item"
    innerRef={ref}
  />
));

const DrawerItem = (props) => (
  <ListItem
    button
    onClick={props.onClick}
    component={renderLink}
    to={props.path}
    className="drawer-item"
  >
    <ListItemIcon className="drawer-item-icon">
      <props.icon fontSize="inherit" />
    </ListItemIcon>
    <ListItemText
      primary={props.label}
      primaryTypographyProps={{
        className: 'drawer-item-label',
      }}
    />
  </ListItem>
);


export default DrawerItem;
