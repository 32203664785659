import { initAxios } from 'service/axios';

export const createAccount = (data) => initAxios().then((axios) => axios.put('/account', data));
export const getAccount = () => initAxios().then((axios) => axios.get('/account'));
export const upsertLocation = (data) => initAxios().then((axios) => axios.put('/loc', data));
export const getLocation = (locationId) => initAxios().then((axios) => axios.get(`/loc/${locationId}`));
export const getLocations = () => initAxios().then((axios) => axios.get('/loc'));
export const incCount = (locationId) => initAxios().then((axios) => axios.post(`/loc/${locationId}/inc`));
export const decCount = (locationId) => initAxios().then((axios) => axios.post(`/loc/${locationId}/dec`));
export const getCounters = (locationId) => initAxios().then((axios) => axios.get(`/loc/${locationId}/counters`));
export const getCounter = (locationId, counterId) => initAxios().then((axios) => axios.get(`/loc/${locationId}/counters/${counterId}`));
export const upsertCounter = (locationId, data) => initAxios().then((axios) => axios.put(`/loc/${locationId}/counters`, data));
export const incCounter = (locationId, counterId) => initAxios().then((axios) => axios.post(`/loc/${locationId}/counters/${counterId}/inc`));
export const decCounter = (locationId, counterId) => initAxios().then((axios) => axios.post(`/loc/${locationId}/counters/${counterId}/dec`));
export const deleteCounter = (locationId, counterId) => initAxios().then((axios) => axios.delete(`/loc/${locationId}/counters/${counterId}`));
export const resetCounters = (locationId) => initAxios().then((axios) => axios.post(`/loc/${locationId}/reset`));
export const resetCounter = (locationId, counterId) => initAxios().then((axios) => axios.post(`/loc/${locationId}/counters/${counterId}/reset`));
export const getCharts = (query = '') => initAxios().then((axios) => axios.get(`/charts?${query}`));
