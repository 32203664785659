import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';

import { isRequired, noZero, noNegative, isInvalid } from 'service/utility/errorMessages';

import RootField from './RootField';


const NumberFormatCustom = (props) => {
  const onValueChange = (values) => {
    props.onChange({
      target: {
        value: values.value,
      },
    });
  };

  const { inputRef, onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={onValueChange}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};


class NumberField extends Component {
  static propTypes = {
    allowDecimals: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowZero: PropTypes.bool,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    inputProps: PropTypes.object,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    validate: PropTypes.func,
    value: PropTypes.number,
  };

  static defaultProps = {
    allowDecimals: true,
    allowNegative: true,
    allowZero: true,
    autoFocus: false,
    error: '',
    fullWidth: false,
    inputProps: {},
    label: '',
    required: false,
    value: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount = () => {
    const { value, onChange } = this.props;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  };

  componentWillUnmount = () => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  };

  validateSelf = (value) => {
    const { allowNegative, allowZero, validate, required, label } = this.props;

    if (value === null && required) return isRequired(label);
    if (value === 0 && !allowZero) return noZero(label);
    if (value < 0 && !allowNegative) return noNegative(label);

    return validate ? validate(value) : null;
  };

  handleLeaveFocus = () => {
    const { value, onChange } = this.props;
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  handleChangeSelf = (rawValue) => {
    const { label, onChange } = this.props;
    const value = rawValue === '' || rawValue === null ? null : Number(rawValue);
    const error = isNaN(value) ? isInvalid(label) : this.validateSelf(value);

    if (onChange) {
      onChange({ value, error });
    }
  };

  render() {
    const {
      allowDecimals, allowNegative, allowZero, className, error, inputProps, onChange, validate, value: rawValue, ...rest
    } = this.props;

    const value = rawValue === null ? '' : rawValue;
    const { blurred } = this.state;

    return (
      <RootField
        value={value}
        error={blurred && Boolean(error)}
        helperText={error}
        onChange={(e) => this.handleChangeSelf(e.target.value)}
        onBlur={this.handleLeaveFocus}
        className={classnames('number-field', { [className]: className })}
        InputProps={{
          inputProps: {
            ...(allowDecimals ? {} : { decimalScale: 0 }),
            ...inputProps,
          },
          inputComponent: NumberFormatCustom,
        }}
        {...rest}
      />
    );
  }
}


export default NumberField;
