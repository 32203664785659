import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Button } from '@material-ui/core';

import { appActions, appSelectors } from 'app/redux';
import { Spinner, ButtonSpinner } from 'common/statusIndicators';
import DISPLAY_TYPES from 'config/displayTypes';
import * as NAVIGATION from 'config/navigation';
import * as crud from 'service/crud';


const DEFAULT_DISPLAY_TYPE = DISPLAY_TYPES[0].value;

class Options extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      displayType: DEFAULT_DISPLAY_TYPE,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.loadLocation();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };


  handleError = (error) => {
    this.setState({
      loading: false,
      saving: false,
    });

    console.log('Options error: ');
    console.log(error);

    toast.error(error);
  };

  
  loadLocation = async () => {
    await crud.getLocation({
      locationId: this.props.currentLocations[0].id,
      onSuccess:this.afterLocationIsRetrieved,
      onError: this.handleError,
    });
  };

  afterLocationIsRetrieved = async (location) => {
    this.setState({
      displayType: location.displayType || DEFAULT_DISPLAY_TYPE,
      loading: false,
    });
  };


  saveLocation = async () => {
    const { displayType } = this.state;

    this.setState({ saving: true });

    const location = {
      id: this.props.currentLocations[0].id,
      displayType,
    };

    await crud.upsertLocation({
      newLocation: location,
      onSuccess: this.loadLocations,
      onError: this.handleError,
    });
  };

  loadLocations = async () => {
    await crud.getLocations({
      onSuccess: this.afterLocationsAreRetrieved,
      onError: this.handleError,
    });
  };

  afterLocationsAreRetrieved = async (locations) => {
    this.props.setLocations(locations);

    await this.finish();
  };


  finish = () => {
    this.props.history.push(NAVIGATION.MAIN);
  };


  handleDisplayTypeChange = (event) => {
    this.setState({ displayType: event.target.value });
  };


  render() {
    const { loading, saving, displayType } = this.state;

    return (
      <div className="onboarding-page light-gradient">
        <div className="inner-container">
          <div className="form">
            <span className="inner-title">
              {'Options'}
            </span>
            {loading ? (
              <Spinner />
            ) : (
              <FormControl margin="normal">
                <FormLabel>
                  {'Display live counter(s) as'}
                </FormLabel>
                <RadioGroup
                  value={displayType}
                  onChange={this.handleDisplayTypeChange}
                >
                  {DISPLAY_TYPES.map((type) => (
                    <FormControlLabel
                      key={type.value}
                      label={type.label}
                      value={type.value}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </div>
          <div className="button-wrapper">
            {!loading && (
              <Button
                variant="contained"
                className="main-button"
                disabled={saving}
                onClick={this.saveLocation}
              >
                {saving && <ButtonSpinner />}
                {'SAVE & RETURN'}
              </Button>
            )}
            <Button
              className="cancel-button"
              component={Link}
              to={NAVIGATION.MAIN}
              disabled={saving}
            >
              {'Cancel'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Options.propTypes = {
  currentLocations: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  setLocations: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  currentLocations: appSelectors.currentLocations(state),
});

const mapDispatchToProps = (dispatch) => ({
  setLocations: (locations) => dispatch(appActions.setLocations(locations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Options);
