import styles from 'styles/export.module.scss';

const THEME_CONFIG = {
  typography: {
    fontFamily: styles.fontFamilySansSerif,
  },
  breakpoints: {
    values: {
      xs: Number(styles.gridBreakpointxs),
      sm: Number(styles.gridBreakpointsm),
      md: Number(styles.gridBreakpointmd),
      lg: Number(styles.gridBreakpointlg),
      xl: Number(styles.gridBreakpointxl),
    },
  },
};

export default THEME_CONFIG;
