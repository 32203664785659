import { authSagas } from 'app/redux';

const sagasList = [
  ...authSagas,
];

const initSagas = (sagaMiddleware) => {
  sagasList.forEach(sagaMiddleware.run.bind(sagaMiddleware));
};


export default initSagas;
