import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MuiThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

import { appSelectors } from 'app/redux';
import THEME_CONFIG from 'config/theme';
import TOAST_CONFIG from 'config/toast';

import Layout from './layout';
import Routes from './Routes';


toast.configure(TOAST_CONFIG);

const theme = createMuiTheme(THEME_CONFIG);


const App = () => {
  const serviceWorker = useSelector(appSelectors.serviceWorker);

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorker.registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  if (serviceWorker.initialized) {
    console.log('App: Service Worker is initialized for the first time');
  }

  if (serviceWorker.updated) {
    updateServiceWorker();

    return null;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <Layout>
          <Routes />
        </Layout>
      </BrowserRouter>
    </MuiThemeProvider>
  );
};

export default App;
