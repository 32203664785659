import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import { TextField, Portal } from '@material-ui/core';

import { isRequired } from 'service/utility/errorMessages';
import * as timeUtils from 'service/utility/dateTime';
import "react-datepicker/dist/react-datepicker.css";


const DatePickerWrapper = ({ children }) => (
  <Portal>
    <div>
      {children}
    </div>
  </Portal>
);

DatePickerWrapper.propTypes = {
  children: PropTypes.any,
};


class TimeField extends React.PureComponent {
  static propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    DatePickerProps: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    narrow: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    autoFocus: false,
    DatePickerProps: {},
    error: '',
    fullWidth: false,
    narrow: false,
    required: false,
    value: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  componentDidMount = () => {
    const { value: stringValue, onChange } = this.props;
    const value = stringValue || null;
    const error = this.validateSelf(value);
    
    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.required !== this.props.required || prevProps.value !== this.props.value) {
      const { value: stringValue, onChange } = this.props;
      const value = stringValue || null;
      const error = this.validateSelf(value);

      if (onChange) {
        onChange({ error });
      }
    }
  };

  componentWillUnmount = () => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }
  };

  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value && required) return isRequired(label);

    return null;
  };

  handleLeaveFocus = () => {
    const { value: stringValue, onChange } = this.props;
    const value = stringValue || null;
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ error });
    }
  };

  handleChangeSelf = (jsDateValue) => {
    const { onChange } = this.props;
    const value = jsDateValue ? timeUtils.dateHoursAndMinutes(jsDateValue) : null;

    if (onChange) {
      onChange({ value });
    }
  };

  render() {
    const {
      autoFocus, className, disabled, error, fullWidth, onChange, label, narrow, required,
      value: stringValue, DatePickerProps, ...rest
    } = this.props;
    const { blurred } = this.state;

    const timeFieldClassName = classnames('time-field', { narrow, [className]: Boolean(className) });
    const dateFormat = timeUtils.getSimpleTimeDatePickerFormat();
    const jsDateValue = !stringValue ? null : timeUtils.localDateFromIso8601Time(stringValue);
    const showError = blurred && Boolean(error);
    
    return (
      <div className={timeFieldClassName}>
        <DatePicker
          dateFormat={dateFormat}
          selected={jsDateValue}
          onChange={this.handleChangeSelf}
          onBlur={this.handleLeaveFocus}
          required={required}
          autoFocus={autoFocus}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          customInput={
            <TextField
              error={showError}
              helperText={error}
              required={required}
              fullWidth={fullWidth}
              label={label}
              className="m-3"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
              {...rest}
            />
          }
          disabled={disabled}
          popperContainer={DatePickerWrapper}
          {...DatePickerProps}
        />
      </div>
    );
  }
}

export default TimeField;
